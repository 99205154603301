import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Button, Space, Tag, Tooltip,
} from 'antd';
import DietaryTags from 'components/DietaryTags';
import React from 'react';
import { priceTransformer } from 'utils';

const columns = ({
  editingOption, setEditingOption, tempEditingOption, onSave, onRemoveItem, getNestedOptions,
}) => ([
  {
    key: 'sort',
    width: 30,
    align: 'right',
  },
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    editable: true,
  },
  {
    title: 'Tags',
    key: 'dietary_tags',
    dataIndex: 'dietary_tags',
    editable: true,
    width: 150,
    render: (value) => {
      return <DietaryTags tags={value} />
    },
  },
  {
    title: 'Price',
    key: 'real_price',
    dataIndex: 'real_price',
    width: 80,
    align: 'right',
    editable: true,
    render: (value, record) => {
      if (!record.modifiers_children?.length) return priceTransformer(value);

      // for handle before selected or current selected nested modifier
      const options = (record.localId ? record.modifiers_children : getNestedOptions(record.modifiers_children))
        ?.map((modifier) => (
          <Tag key={modifier.id} color={modifier.modifiers_children?.length ? 'green' : ''}>
            {modifier.title}
            {modifier.modifiers_children?.length > 0 && ' (nested)'}
          </Tag>
        ));
      return (
        <Space style={{ color: '#ADB2B8' }}>
          Nested
          <Tooltip
            title={<Space size={[0, 4]} wrap>{options}</Space>}
            color="white"
            placement="topRight"
            overlayStyle={{ maxWidth: '400px' }}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      );
    },
  },
  {
    title: '',
    key: 'operation',
    width: 70,
    align: 'center',
    render: (_, record) => (
      (record.key === editingOption?.key && !editingOption.modifiers_children?.length) ? (
        <Space size={2}>
          <Button type="text" icon={<CloseOutlined />} onClick={() => setEditingOption()} />
          <Button type="primary" icon={<CheckOutlined />} onClick={onSave} disabled={!tempEditingOption.current.title} />
        </Space>
      ) : (
        <Space size={0}>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingOption(record);
              tempEditingOption.current = record;
            }}
          />
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={(e) => onRemoveItem(e, record.id || record.localId)}
          />
        </Space>
      )
    ),
  },
]);

export default columns;
