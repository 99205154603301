import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Input, InputNumber, Row,
  Typography,
} from 'antd';
import React from 'react';

function Sizes({
  item, isCatering, onChangeSize, onAddSize, onRemoveSize,
}) {
  if (!item) {
    return;
  }
  return (
    <Flex vertical gap={8}>
      <Row gutter={8} align="middle">
        <Col span={5}><Typography.Text strong>Price *</Typography.Text></Col>
        {
          item.sizes?.length > 1 && (
            <Col span={7}><Typography.Text strong>Size *</Typography.Text></Col>
          )
        }
        {
          isCatering && <Col span={7}><Typography.Text strong>Servings per unit</Typography.Text></Col>
        }

      </Row>
      {item?.sizes?.map((option, index) => renderSize(option, index))}
    </Flex>
  );
  function renderSize(o, index) {
    return (
      <Row gutter={8} align="middle" key={index}>
        <Col span={5}>
          <InputNumber
            value={Number(o.price)}
            type="number"
            prefix="$"
            style={{ width: '100%' }}
            onChange={(price) => onChangeSize({ index, price: Math.max(price || 0, 0) })}
            status={(o.price !== 0 && !o.price) && 'error'}
          />
        </Col>
        {
          item.sizes?.length > 1 && (
            <Col span={7}>
              <Input
                value={o.name}
                style={{ width: '100%' }}
                onChange={(e) => onChangeSize({ index, name: e.target.value })}
                status={!o.name && 'error'}
              />
            </Col>
          )
        }
        {
          isCatering && (
            <Col span={7}>
              <InputNumber
                value={o.serving_size}
                style={{ width: '100%' }}
                onChange={(serving_size) => onChangeSize({ index, serving_size })}
                suffix={<Typography.Text type="secondary">People</Typography.Text>}
                min={1}
              />
            </Col>
          )
        }
        <Col span={5}>
          {index === 0 && (
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={() => onAddSize()}
            >
              Add
            </Button>
          )}
          {index > 0 && (
            <Button
              type="text"
              icon={<MinusOutlined />}
              style={{ marginLeft: 8 }}
              onClick={() => onRemoveSize(index)}
            />
          )}
        </Col>

      </Row>
    );
  }
}

export default Sizes;
