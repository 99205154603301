import {
  Col, Divider, Flex, Input, Row, Space, Switch, Typography,
} from 'antd';
import { ModifierContext } from 'containers/MenuManagement/context';
import React, { useContext } from 'react';
import Tags from 'components/DietaryTags/TagSelector';
import CateringUnit from './CateringUnit';
import Modifiers from './Modifiers';
import { MenuContext } from '../../../context';
import AdditionalTax from './AdditionalTax';
import { getItemImages } from '../utils';
import UploadItemImage from './UploadItemImage';
import Sizes from './Sizes';

function ItemForm({ detail, setDetail }) {
  const { showMessage } = useContext(MenuContext);
  const { merchant } = useContext(ModifierContext);
  const onChangeDetail = (key, value) => {
    setDetail({ ...detail, [key]: value });
  };

  const onChangeSize = ({ index, ...payload }) => {
    const sizes = [...detail.sizes];
    sizes[index] = {
      ...sizes[index],
      ...payload,
    };
    onChangeDetail('sizes', sizes);
  };
  const onAddSize = () => {
    if (detail.sizes.length < 4) {
      setDetail({
        ...detail,
        sizes: [...detail.sizes, {
          position: detail.sizes.length + 1,
          serving_size: 1,
          name: '',
        }],
      });
    } else {
      showMessage({ type: 'error', content: 'You can define just 4 sizes!' });
    }
  };
  const onRemoveSize = (index) => {
    const sizes = [...detail.sizes];
    sizes.splice(index, 1);
    onChangeDetail('sizes', sizes);
  };

  return (
    <Flex vertical>
      <Flex vertical gap={16}>
        <Flex vertical gap={8}>
          <Typography.Text strong>Name</Typography.Text>
          <Input placeholder="Item name" value={detail.name} onChange={(e) => onChangeDetail('name', e.target.value)} />
        </Flex>

        <Space style={{ background: '#E6F3EB', borderRadius: 5, padding: 16 }} direction="vertical">
          <Flex justify="space-between" align="center">
            <Typography.Text strong>Mark as available</Typography.Text>
            <Switch
              defaultChecked
              checked={detail.isActive}
              onChange={(value) => onChangeDetail('isActive', value)}
              style={{ marginRight: 8 }}
            />
          </Flex>
          <Typography.Text>
            Customers can view item and select it.
          </Typography.Text>
        </Space>

        {merchant?.is_catering && (
          <>
            <Divider />
            <CateringUnit detail={detail} onChangeDetail={onChangeDetail} />
          </>
        )}
        <Divider />
        <Sizes
          item={detail}
          onChangeSize={onChangeSize}
          onAddSize={onAddSize}
          onRemoveSize={onRemoveSize}
          isCatering={merchant?.is_catering}
        />
        <AdditionalTax detail={detail} onChange={onChangeDetail} />
        <Divider />
        <Row gutter={[8, 16]} align="middle">
          <Col xs={24} md={18}>
            <Flex vertical gap={8}>
              <Typography.Text strong>
                Description
                <Typography.Text type="secondary"> (optional)</Typography.Text>
              </Typography.Text>
              <Input.TextArea
                value={detail.description}
                rows={3}
                onChange={(e) => onChangeDetail('description', e.target.value)}
              />
            </Flex>
          </Col>
          <Col xs={24} md={6}>
            <UploadItemImage image={getItemImages(detail.image)} onChange={onChangeDetail} />
          </Col>
          <Col span={24}>
            <Tags detail={detail} onChangeDetail={onChangeDetail} />
          </Col>
        </Row>

      </Flex>
      <Divider />

      <Modifiers modifiers={detail.modifiers} onChangeModifiers={(modifiers) => onChangeDetail('modifiers', modifiers)} />

    </Flex>
  );
}

export default ItemForm;
