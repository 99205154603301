import { Divider, Radio } from 'antd';
import Modifier from './Modifier';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { modifierContext } from '../ModifierProvider';
import OptionRow from '../OptionRow';
import { getModifierMinSelections, isAutoApplied } from '../utils';

function SingleSelectModifier({ modifier, disabled }) {
  const { isSelected, handleChange, selectedModifiers } = useContext(modifierContext);
  useEffect(() => {
    if (!isAutoApplied(modifier) || modifier.options.some((option) => isSelected({ modifier, option }))) {
      return;
    }
    handleChange({ modifier, option: modifier.options[0], value: { ...modifier.options[0], qty: 1 } });
  }, [modifier, handleChange, isSelected]);

  const selectedOption = useMemo(() => {
    let selectedId = null;
    modifier.options.forEach((option) => {
      if (isSelected({ option, modifier })) {
        selectedId = option.id;
      }
    });
    return selectedId;
  }, [modifier.options, selectedModifiers]);

  return (
    <Radio.Group
      style={{ width: '100%' }}
      name={modifier.id}
      value={selectedOption}
    >
      {modifier.options.map((option, index) => (
        <ModifierOption modifier={modifier} option={option} disabled={disabled} showDivider={index !== modifier.options.length - 1}/>
      ))}
    </Radio.Group>
  );
}
const ModifierOption = ({ modifier, disabled, option, showDivider }) => {
  const { isSelected, handleChange, getNestedOptions } = useContext(modifierContext);
  const [showChildren, setShowChildren] = useState();
  const checked = isSelected({ option, modifier });

  return (
    <div key={option.id}>
      <Radio
        value={option.id}
        checked={checked}
        disabled={disabled}
        className="omc-select-row"
        onClick={() => {
          setShowChildren(!showChildren);
          if (checked && getModifierMinSelections(modifier) === 0) {
            return handleChange({
              modifier,
              option,
              value: undefined,
            });
          }
          handleChange({
            modifier,
            option,
            value: {
              ...option,
              qty: 1,
            },
          });
        }}
      >
        <OptionRow option={option} showChildren={showChildren} />
      </Radio>
      {showChildren && getNestedOptions(option.id)?.length > 0 && getNestedOptions(option.id)?.map((m, index) => (
        <Modifier modifier={m} key={index} isNested={true}/>
      ))}
      {showDivider && <Divider className="my-3" />}
    </div>
  );
}

export default SingleSelectModifier;
