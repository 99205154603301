import { Flex, Skeleton, Typography } from 'antd';
import React, { useEffect, useContext, useMemo } from 'react';
import Modifier from './Modifier';
import { modifierContext } from '../ModifierProvider';

function Modifiers({ errorVersion }) {
  const { unSatisfiedModifiers, loading, modifiers } = useContext(modifierContext);

  // TODO: use event
  useEffect(() => {
    if (unSatisfiedModifiers.length === 0) {
      return;
    }
    document
      .getElementById(getModifierId(unSatisfiedModifiers[0]))
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
  }, [errorVersion]);

  function getModifierId(modifier) {
    return `app-modifier-${modifier.id}`;
  }

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }

  const rootModifiers = useMemo(() => (
    modifiers.filter(m => !m.options_parents.length)
  ), [modifiers]);

  return (
    <>
      <Typography.Text className="font-bold text-base mb-4">Modifiers</Typography.Text>
      <Flex vertical gap={24}>
        {rootModifiers.map((m, index) => (
          <Modifier modifier={m} key={index} />
        ))}
      </Flex>
    </>
  );
}

export default Modifiers;
