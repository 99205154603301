import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Flex, Input, Space, Tag, Typography,
} from 'antd';
import SkeletonLoading from 'components/common/SkeletonLoading';
import Banners from 'components/Layout/Header/Banners';
import useIsDesktop from 'hooks/useIsDesktop';
import { useHistory } from 'react-router-dom';
import React from 'react';

const { Title } = Typography;

function Header({
  title,
  description,
  tag,
  extra,
  onSearch,
  searchPlaceholder = 'Search...',
  backTitle,
  backPath,
  loading = false,
}) {
  const isDesktop = useIsDesktop();
  const history = useHistory();

  const goBack = () => {
    if (backPath) {
      history.push(backPath);
    } else {
      history.push('/console');
    }
  };
  return (
    <>
      {onSearch && (
        <Input
          size="large"
          placeholder={searchPlaceholder}
          prefix={<SearchOutlined className="mr-2" />}
          onChange={onSearch}
          className="rounded-none h-[50px] pl-4 lg:pl-12 pr-8 border-l-0 border-r-0"
          allowClear
        />
      )}

      <Flex vertical className="ml-5 lg:ml-16 mr-4 lg:mr-8 mt-8">
        {backTitle && (
          <Flex onClick={goBack} gap={8} style={{ cursor: 'pointer', marginBottom: 24, color: '#717679' }}>
            <ArrowLeftOutlined />
            <span className="font-weight-bolder">{backTitle}</span>
          </Flex>
        )}

        <Banners />

        {loading && (
          <SkeletonLoading
            borderRadius="2px"
            width="250px"
            height="36px"
            count={1}
          />
        )}

        {!loading && (
          <Flex justify="space-between" align="center">
            <Space direction="vertical" className="w-100">
              <Flex justify="space-between" gap={4} className="flex-column flex-md-row">
                <Space className="flex-column flex-md-row align-items-start align-items-md-center">
                  <Title level={isDesktop ? 2 : 3} className="font-weight-bold mb-0">
                    {title}
                  </Title>
                  {tag && (typeof tag === 'string' ? <Tag>{tag}</Tag> : tag)}
                </Space>
                {extra && (
                  <Flex gap={4} className="flex-column flex-sm-row">
                    {extra}
                  </Flex>
                )}
              </Flex>
              {description && (
                <p dangerouslySetInnerHTML={{ __html: description }} style={{ color: '#10111299' }} />
              )}
            </Space>
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default Header;
