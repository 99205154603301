import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BankIcon from 'components/icons/BankIcons';
import StripePaymentModal from 'components/StripePaymentModal';
import React, { useCallback, useEffect, useState } from 'react';
import useConfig from 'hooks/useConfig';
import ExtraCommissionInput from './ExtraCommissionInput';
import StripeContainer from './StripeContainer';
import { deleteMerchantCard, fetchMerchantCard } from './requests';

function PaymentInfo({ merchantId }) {
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [card, setCard] = useState();
  const stripeKey = useConfig('general.stripeApiPublicKey');

  const getMerchantPaymentCard = useCallback(() => {
    fetchMerchantCard(merchantId).then((res) => {
      setCard(res.data?.card?.card);
    });
  }, [merchantId]);

  useEffect(() => {
    getMerchantPaymentCard();
  }, [getMerchantPaymentCard]);

  const onDeleteCard = () => deleteMerchantCard(merchantId);
  return (
    <div className="box">
      <div className="my-4 flex align-items-center">
        {card && (
          <>
            <div
              style={{
                display: 'inline-flex',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 'fit-content',
                padding: '6px 12px',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid #D9D9D9',
                borderRadius: 4,
              }}
            >
              <BankIcon
                type={card?.brand}
                style={{ marginRight: '12px', display: 'flex' }}
              />
              <span style={{ marginRight: 4, textTransform: 'capitalize' }}>
                  {card?.brand}
                </span>
              <span>
                  {' '}
                ****
                {card?.last4}
                </span>
            </div>
            <Button size="large" onClick={() => setStripeModalOpen(true)} style={{ marginLeft: '8px' }}>
              edit
            </Button>
          </>
        )}
        {!card && (
          <Button onClick={() => setStripeModalOpen(true)} icon={<PlusOutlined />}>
            Add credit card
          </Button>
        )}

        <StripePaymentModal
          container={StripeContainer}
          containerProps={{
            merchantId,
            stripeKey,
          }}
          reloadCards={getMerchantPaymentCard}
          onClose={() => {
            setStripeModalOpen(false);
          }}
          open={stripeModalOpen}
          title="Add credit card"
          subtitle=""
          onBeforeSubmit={card ? onDeleteCard : null}
        />
      </div>

      <ExtraCommissionInput />
    </div>
  );
}

export default PaymentInfo;
