import {
  Flex, Form, Space, Typography,
} from 'antd';
import { getTotalOrderValue } from 'components/ItemsInput/ItemDetailOverlay/item';
import { getImage } from 'utils/image';
import React from 'react';
import { priceTransformer } from 'utils';
import { useIsCatering } from '../useIsCatering';

function Summary() {
  const form = Form.useFormInstance();
  const items = form.getFieldValue('items');
  const headcount = form.getFieldValue('headcount');
  const isCatering = useIsCatering();

  return (
    <Flex gap={32}>
      <Space>
        {items?.map(({ item_related }) => (getImage({ data: item_related }) && (
          <img
            key={item_related.id}
            className="image"
            src={getImage({ data: item_related })}
            alt={item_related.name || ''}
            width={20}
            height={20}
            style={{ border: '1px solid #DDE1E4', borderRadius: 5 }}
          />
        )))}
      </Space>
      {isCatering && (
        <Typography>
          Headcount:
          {' '}
          {headcount}
          {' '}
          people
        </Typography>
      )}
      <Typography>
        Total:
        {' '}
        {priceTransformer(getTotalOrderValue(items))}
      </Typography>
    </Flex>
  );
}
export default Summary;
