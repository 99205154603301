export function getItemImage(item, size = 'sm') {
  const SIZE_MAP = {
    xs: 'thumbnail',
    sm: 'small',
    md: 'med',
    lg: 'large',
  };
  const image = item.images?.[0]?.[`image_${SIZE_MAP[size]}`] || item.image;
  if (image) {
    return image;
  }
  // Todo: uncomment if needed
  // if (item.are_item_images_visible && config?.placeholder_img) {
  //   return config?.placeholder_img;
  // }
  if (item.are_item_images_visible && item.placeholder_image) {
    return item.placeholder_image;
  }
  return null;
}

export const getItemPrices = ({ item, size } = {}) => {
  if (!size) {
    size = +item?.item_types?.[0]?.size_id;
  }
  const price = +item?.item_types?.find((d) => d?.size_id === size)?.price;
  const discountedPrice = item?.discount?.discounted_prices?.find(
    (d) => d?.size_id === size,
  )?.discounted_price;

  return { price, discountedPrice: discountedPrice ? +discountedPrice : null };
};

export const getItemTotalPrice = ({ item_related, size, qty, order_options }) => {
  const prices = getItemPrices({ item: item_related, size });
  const itemPrice = prices.discountedPrice || prices.price;
  const modifiersPrice = getModifiersPrice(order_options ?? []);
  return (qty || 1) * (itemPrice + modifiersPrice);
};

export function getModifiersPrice(modifiers) {
  return modifiers.reduce(
    (total, m) => total + (m.price || 0) * m.qty,
    0,
  );
}
