import React from 'react';

function StarIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.33331" y="1.3335" width="13.3333" height="13.3333" rx="4" fill="black" fillOpacity="0.45" />
      <g clipPath="url(#clip0_15845_21914)">
        <path d="M6.70723 6.18791L4.22612 6.54763L4.18217 6.55658C4.11565 6.57424 4.055 6.60924 4.00643 6.658C3.95786 6.70676 3.9231 6.76755 3.9057 6.83414C3.8883 6.90073 3.88888 6.97075 3.9074 7.03704C3.92591 7.10333 3.96168 7.16352 4.01106 7.21147L5.80851 8.96108L5.38462 11.4325L5.37956 11.4752C5.37549 11.544 5.38978 11.6127 5.42096 11.6742C5.45214 11.7356 5.4991 11.7877 5.55703 11.8251C5.61495 11.8624 5.68176 11.8837 5.75062 11.8867C5.81948 11.8898 5.8879 11.8745 5.9489 11.8424L8.1679 10.6757L10.3818 11.8424L10.4207 11.8602C10.4849 11.8855 10.5547 11.8933 10.6229 11.8827C10.691 11.8721 10.7552 11.8436 10.8087 11.8001C10.8622 11.7565 10.9032 11.6995 10.9274 11.6349C10.9516 11.5703 10.9582 11.5005 10.9465 11.4325L10.5222 8.96108L12.3205 7.21108L12.3508 7.17802C12.3941 7.12465 12.4225 7.06075 12.4331 6.99283C12.4437 6.92491 12.4361 6.85539 12.4111 6.79136C12.3861 6.72733 12.3445 6.67108 12.2907 6.62833C12.2369 6.58559 12.1727 6.55787 12.1046 6.54802L9.62351 6.18791L8.5144 3.94013C8.4823 3.87501 8.43262 3.82017 8.37097 3.78182C8.30932 3.74347 8.23817 3.72314 8.16556 3.72314C8.09296 3.72314 8.0218 3.74347 7.96016 3.78182C7.89851 3.82017 7.84882 3.87501 7.81673 3.94013L6.70723 6.18791Z" fill="black" fillOpacity="0.45" />
      </g>
      <defs>
        <clipPath id="clip0_15845_21914">
          <rect width="9.33333" height="9.33333" fill="white" transform="translate(3.50165 3.3335)" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default StarIcon;
