import {
  Button, Col, Drawer, Flex, Form, Input, Row,
} from 'antd';
import { createCustomer } from 'api/customers';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';

function NewCustomerForm({ open, onClose, onChange }) {
  const [loading, setLoading] = useState(false);
  const [newCustomerForm] = Form.useForm();

  const onCloseCustomerForm = () => {
    newCustomerForm.resetFields();
    onClose();
  };
  const onCreateCustomer = (data) => {
    setLoading(true);
    const customer = { phone: `+${data.phone}`, name: data.name, email: data.email };
    createCustomer(customer)
      .then((res) => {
        onChange({
          id: res.data.id,
          key: customer.phone,
          value: customer.phone,
          label: customer.phone,
          ...customer,
        });
        onCloseCustomerForm();
      })
      .catch((e) => {
        captureException(e);
        console.log(e.response);
        errorHandling(e.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Drawer
      title="Create user"
      width={572}
      onClose={onCloseCustomerForm}
      open={open}
    >
      <Form form={newCustomerForm} layout="vertical" onFinish={onCreateCustomer}>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <FormItem name="phone" label="Phone number" fullWidth rules={[{ required: true }]}>
              <PhoneInput
                country="us"
                buttonClass="phone-country"
                inputClass="input"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{ width: '100%' }}
                placeholder="Enter phone number"
                autofocus
                preferredCountries={['us', 'ca']}
                enableSearch
                regions={['america', 'europe']}
                countryCodeEditable={false}
                masks={{
                  pr: '(...) ...-....',
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <FormItem name="name" label="Name" fullWidth rules={[{ required: true }]}>
              <Input placeholder="Enter the customer name..." />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem name="email" label="Email" fullWidth rules={[{ required: true, type: 'email' }]}>
              <Input placeholder="Enter the customer email..." />
            </FormItem>
          </Col>
        </Row>
        <FormLayout.Footer>
          <Flex gap={16} justify="flex-end">
            <Button disabled={loading} onClick={onCloseCustomerForm}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Create
            </Button>
          </Flex>
        </FormLayout.Footer>
      </Form>
    </Drawer>
  );
}

export default NewCustomerForm;
