import {
  Col, Form, Radio, Row, Space,
} from 'antd';
import React from 'react';
import OrderFormItem from '../OrderFormItem';
import Addresses from './Addresses';

function OrderType() {
  const has_delivery = Form.useWatch('has_delivery');

  return (
    <Row>
      <Col xs={24} lg={7}>
        <OrderFormItem name="has_delivery" label="Order type" fullWidth rules={[{ required: true }]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={true}>Delivery</Radio>
              <Radio value={false}>Pickup</Radio>
            </Space>
          </Radio.Group>
        </OrderFormItem>
      </Col>
      {has_delivery && (
        <Col xs={24} lg={17}>
          <OrderFormItem name="address" fullWidth rules={[{ required: true }]}>
            <Addresses />
          </OrderFormItem>
        </Col>
      )}
    </Row>
  );
}
export default OrderType;
