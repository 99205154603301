import { EllipsisOutlined } from '@ant-design/icons';
import {
  Flex, Space, Tag, Tooltip,
} from 'antd';
import React from 'react';
import AwardIcon from './AwardIcon';
import DesertIcon from './DesertIcon';
import DrinkIcon from './DrinkIcon';
import GlutenFreeIcon from './GlutenFreeIcon';
import HalalIcon from './HalalIcon';
import HealthyIcon from './HealthyIcon';
import KosherIcon from './KosherIcon';
import SpicyIcon from './SpicyIcon';
import StarIcon from './StarIcon';
import UtensilIcon from './UtensilIcon';
import VeganIcon from './VeganIcon';
import VegetarianIcon from './VegetarianIcon';

function DietaryTags({
  tags, maxCount = 2, hasTagStyle = true, showTitle, wrapperStyle,
}) {
  if (!tags) return null;
  const options = tags.map((option) => (
    TAGS[option]?.icon && (hasTagStyle ? (
      <Tag
        key={option}
        bordered={false}
        icon={TAGS[option].icon}
        style={{
          display: 'flex', alignItems: 'center', gap: 4, height: 22, padding: '0 6px', background: '#F1F2F3',
        }}
      >
        {showTitle ? TAGS[option].label : ''}
      </Tag>
    ) : TAGS[option].icon)
  ));
  return (
    <Flex gap={4} align="center" style={wrapperStyle}>
      {options.slice(0, maxCount)}
      {options.length > maxCount && (
        <Tooltip
          title={options.length > 3 ? <Space size={[0, 6]} wrap>{options}</Space> : ''}
          color="white"
          placement="bottomRight"
          overlayStyle={{ maxWidth: '600px' }}
        >
          <Tag bordered={false}><EllipsisOutlined /></Tag>
        </Tooltip>
      )}
    </Flex>
  );
}

export const TAGS = {
  hasAward: {
    label: 'Award',
    icon: <AwardIcon />,
  },
  isGlutenFree: {
    label: 'Gluten free',
    icon: <GlutenFreeIcon />,
  },
  isHalal: {
    label: 'Halal',
    icon: <HalalIcon />,
  },
  isHealthy: {
    label: 'Healthy',
    icon: <HealthyIcon />,
  },
  isKosher: {
    label: 'Kosher',
    icon: <KosherIcon />,
  },
  isOrHasDessert: {
    label: 'Dessert',
    icon: <DesertIcon />,
  },
  isOrHasDrinks: {
    label: 'Drinks',
    icon: <DrinkIcon />,
  },
  isPopular: {
    label: 'Popular',
    icon: <StarIcon />,
  },
  isSpicy: {
    label: 'Spicy',
    icon: <SpicyIcon />,
  },
  isVegan: {
    label: 'Vegan',
    icon: <VeganIcon />,
  },
  isVegetarian: {
    label: 'Vegetarian',
    icon: <VegetarianIcon />,
  },
  canBeVegetarian: {
    label: 'Can be vegetarian',
    icon: <VegetarianIcon />,
  },
  isUtensils: {
    label: 'Utensil',
    icon: <UtensilIcon />,
  },
};

export default DietaryTags;
