import FormItem from 'components/FormItem';
import React from 'react';

function OrderFormItem({ labelColStyle, ...props }) {
  return (
    <FormItem
      labelCol={{
        style: {
          ...labelColStyle, fontWeight: 700, fontSize: 16, marginBottom: 8,
        },
      }}
      {...props}
    />
  );
}

export default OrderFormItem;
