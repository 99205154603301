import {
  Input
} from 'antd';
import AddressInput from 'components/AddressInput';
import FormItem from 'components/FormItem';
import React from 'react';

function GeneralForm() {
  return (
    <>
      <FormItem
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter the merchant name' }]}
      >
        <Input placeholder="Merchant's name" />
      </FormItem>

      <FormItem
        name="email"
        label="Email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter the email' },
        ]}
      >
        <Input placeholder="e.g. example@example.com" />
      </FormItem>

      <FormItem
        name="phone"
        label="Phone number"
        rules={[
          { required: true, message: 'Please enter the phone number' },
          // { pattern: /^\d{10}$/, message: 'Please enter a valid 10-digit phone number' },
        ]}
      >
        <Input placeholder="Phone number" />
      </FormItem>

      <FormItem
        name="address"
        label="Address"
        rules={[{ required: true, message: 'Please enter the address' }]}
      >
        <AddressInput />
      </FormItem>
    </>
  );
}

export default GeneralForm;
