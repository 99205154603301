import { Flex, Input, Typography } from 'antd';
import useIsDesktop from 'hooks/useIsDesktop';
import React from 'react';

function CateringUnit({ detail, onChangeDetail }) {
  const isDesktop = useIsDesktop();
  return (
    <Flex vertical={!isDesktop} gap={16}>
      <Flex vertical gap={4}>
        <Typography.Text strong>
          Unit
        </Typography.Text>
        <Typography.Text type="secondary">
          Select the unit of measure for this item or the default will be used.
        </Typography.Text>
        <Input
          value={detail.cateringLabel}
          placeholder="e.g. Box"
          onChange={(e) => onChangeDetail('cateringLabel', e.target.value)}
        />
      </Flex>
    </Flex>
  );
}
export default CateringUnit;
