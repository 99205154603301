import { Select } from 'antd';
import React from 'react';

const OPTIONS = ['Admin', 'Direct Entry', 'Android', 'Capacitor-android', 'Capacitor-ios', 'Capacitor-web', 'DOORDASH', 'DataDreamers', 'GFO', 'GFOE', 'MERCHANT WEBSITE', 'OMNICART', 'UBEREATS', 'Web', 'iOS', 'ios-App', 'Catering', 'GFOCatering'].map((source) => ({ value: source, label: source }));
function SourceSelectInput({ value, onChange, ...props }) {
  return (
    <Select
      value={value}
      style={{ width: 200 }}
      onChange={onChange}
      options={OPTIONS}
      mode="multiple"
      placeholder="Sources"
      allowClear
      {...props}
    />

  );
}

export default SourceSelectInput;
