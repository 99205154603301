import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Flex, Form, Radio, Skeleton, Space, Typography,
} from 'antd';
import BankIcon from 'components/icons/BankIcons';
import StripePaymentModal from 'components/StripePaymentModal';
import React, { useEffect, useState } from 'react';
import StripeContainer from './StripeContainer';
import usePaymentMethods from './usePaymentMethods';

function UserCards({ value, onChange }) {
  const form = Form.useFormInstance();
  const customer = form.getFieldValue('customer');
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const {
    loading, reFetch, stripeKey, cards,
  } = usePaymentMethods();

  useEffect(() => {
    if (cards?.length) {
      onChange(cards[0].id);
    }
  }, [cards]);

  if (!customer || !stripeKey) return null;
  return (
    <Flex vertical gap={12} className="bg-[#DAEADE66] py-2 px-4 rounded">
      <Space align="baseline">
        <Typography.Title level={5}>
          Card
        </Typography.Title>
      </Space>

      <Radio.Group
        name="card_id"
        buttonStyle="outline"
        value={value}
      >
        <Space size={16} wrap>
          {loading && <Skeleton.Input />}
          {!loading && (
            <>
              {cards?.map(({ card, id }) => (
                <Radio.Button
                  key={id}
                  style={{ background: 'none', height: 40 }}
                  value={id}
                  onChange={(event) => {
                    onChange(id);
                  }}
                >
                  <div style={{
                    display: 'inline-flex',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: 'fit-content',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  >
                    <BankIcon
                      type={card?.brand}
                      style={{ marginRight: '12px', display: 'flex' }}
                    />
                    <span style={{ marginRight: 4, textTransform: 'capitalize' }}>
                      {card?.brand}
                    </span>
                    <span>
                      {' '}
                      ****
                      {card?.last4}
                    </span>
                  </div>
                </Radio.Button>
              ))}

              <Button
                key="add-order"
                icon={<PlusOutlined />}
                onClick={() => setOpenStripeModal(true)}
                type="default"
                style={{ marginLeft: cards.length ? 0 : '10px' }}
              >
                Add
              </Button>
            </>
          )}
        </Space>
        <StripePaymentModal
          container={StripeContainer}
          containerProps={{
            userId: customer.id,
            stripeKey,
          }}
          reloadCards={reFetch}
          onClose={() => setOpenStripeModal(false)}
          open={openStripeModal}
        />
      </Radio.Group>
    </Flex>
  );
}
export default UserCards;
