import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Row, Typography,
} from 'antd';
import { fetchCustomers } from 'api/customers';
import { Colors } from 'assets/colors';
import DebounceSelect from 'components/Select/DebounceSelect';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import NewCustomerForm from './NewCustomerForm';

function CustomerInput({ value, onChange }) {
  const [open, setOpen] = useState(false);

  const getCustomers = (searchKey = '') => fetchCustomers({ searchKey })
    .then((res) => {
      if (res.status === 'success') {
        const fetchedCustomers = res.data.results?.map((c) => ({
          customerId: c.id,
          name: c.name,
          email: c.email,
          value: c.phone,
          label: c.phone,
          customer: c,
        }));
        return [
          ...fetchedCustomers,
          {
            value: 'new',
          },
        ];
      }
    })
    .catch((e) => {
      captureException(e);
    });

  const customerOptionRenderer = (option, info) => {
    if (option.value === 'new') {
      return (
        <Button
          type="ghost"
          icon={<PlusOutlined />}
          style={{ color: Colors.primary1, textAlign: 'left' }}
          onClick={() => setOpen(true)}
          block
        >
          Create user
        </Button>
      );
    }
    return (
      <Row
        gutter={5}
        align="middle"
        className="px-3"
      >
        <Col xs={24} lg={7}>
          <Typography.Text className="text-[#ADB2B8] font-semibold">
            {option.data.value}
          </Typography.Text>
        </Col>
        {option.data.name && (
          <Col xs={24} lg={8}>
            <Typography.Text ellipsis className="text-[#ADB2B8] font-semibold">
              {option.data.name}
            </Typography.Text>
          </Col>
        )}
        {option.data.email && (
          <Col xs={24} lg={9}>
            <Typography.Text ellipsis className="text-[#ADB2B8] font-semibold">
              {option.data.email}
            </Typography.Text>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <DebounceSelect
        dropdownClassName="customers-dropdown"
        id="customers"
        allowClear
        placeholder="Phone number, Name, or Email"
        fetchOptions={getCustomers}
        showSearch
        optionRender={customerOptionRenderer}
        value={value}
        onChange={(value, option) => {
          onChange((value && value.value !== 'new') ? { ...value, ...option.customer } : null);
        }}
        suffixIcon={value && <CheckCircleFilled style={{ color: '#319D56' }} />}
      />

      <NewCustomerForm
        open={open}
        onClose={() => setOpen(false)}
        onChange={onChange}
      />
    </>
  );
}

export default CustomerInput;
