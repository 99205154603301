import React from 'react';

function VeganIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.33331" y="1.3335" width="13.3333" height="13.3333" rx="4" fill="black" fillOpacity="0.45" />
      <path d="M7.07831 11.269L5.08057 4.73096H6.35186L7.98637 10.1067L9.64813 4.73096H10.9194L8.92168 11.269H7.07831Z" fill="black" fillOpacity="0.45" />
    </svg>
  );
}
export default VeganIcon;
