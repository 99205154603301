import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Flex, Form, Radio, Space, Tooltip, Typography, Skeleton,
} from 'antd';
import { fetchCustomerAddresses } from 'api/address';
import React, { useEffect, useState } from 'react';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import { checkLocationIsSupported } from './requests';
import AddAddressModal from './AddAddressModal';

function Addresses({ value, onChange }) {
  const customer = Form.useWatch('customer');
  const merchant = Form.useWatch('merchant');
  const [loading, setLoading] = useState(true);
  const [checkingZone, setCheckingZone] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [outOfZone, setOutOfZone] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (customer?.id) {
      getCustomerAddresses();
    }
  }, [customer]);

  useEffect(() => {
    if (value && merchant?.id) {
      checkAddressZone();
    }
  }, [value, merchant]);

  const checkAddressZone = () => {
    setCheckingZone(true);
    checkLocationIsSupported({ merchantId: merchant?.id, addressId: value?.id })
      .then(({ data }) => {
        setOutOfZone(!data.is_location_supported);
      })
      .catch(captureException)
      .finally(() => setCheckingZone(false));
  };

  const getCustomerAddresses = () => {
    setLoading(true);
    setAddresses([]);
    setOutOfZone(false);
    fetchCustomerAddresses(customer?.id)
      .then((res) => {
        const data = getData(res);
        setAddresses(data.addresses);
        onChange(data?.addresses?.length ? data?.addresses[0] : null);
      })
      .catch(captureException)
      .finally(() => setLoading(false));
  };

  const onSaveNewAddress = (addressId) => {
    onChange(addressId);
    getCustomerAddresses();
    setOpen(false);
  };
  if (!merchant || !customer) {
    return null;
  }
  return (
    <Flex vertical gap={16} className="bg-[#DAEADE66] py-2 px-4 rounded">
      <Space align="baseline">
        <Typography.Title level={5}>
          Delivery to
        </Typography.Title>
        <Typography.Text style={{ fontWeight: 700, color: '#A7A9A9' }}>
          Required
        </Typography.Text>
      </Space>
      {loading && <Skeleton.Input />}
      {!loading && (
        <Radio.Group value={value}>
          <Space size={[16, 10]} wrap align="center">
            {addresses?.map((address) => (
              <Radio.Button
                style={{
                  width: '200px',
                  background: 'none',
                  height: 40,
                }}
                key={address.id}
                value={address}
                onChange={(event) => {
                  onChange(event?.target?.value);
                }}
              >
                <Tooltip
                  placement="topLeft"
                  title={
                    `${address?.title ? `(${address?.title})` : ''}${
                      address?.address}`
                  }
                >
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      height: '100%',
                      paddingTop: 5,
                    }}
                  >
                    {`${address?.title ? `(${address?.title})` : ''}`}
                    {address?.address}
                  </div>
                </Tooltip>
              </Radio.Button>
            ))}

            <Button
              key="add-order"
              icon={<PlusOutlined />}
              onClick={() => setOpen(true)}
              type="default"
              className="align-self-center"
              disabled={loading}
            >
              Add
            </Button>
          </Space>
        </Radio.Group>
      )}
      {checkingZone && <Skeleton.Input />}
      {!checkingZone && outOfZone && (
        <Typography.Text className="mt-3 text-danger">
          Out of operation area. Please select another
          address.
        </Typography.Text>
      )}

      <AddAddressModal open={open} onClose={() => setOpen(false)} customerId={customer.id} merchantId={merchant.id} onSave={onSaveNewAddress} />
    </Flex>
  );
}

export default Addresses;
