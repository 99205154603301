import {
  Col, Form, Row, Typography,
} from 'antd';
import React from 'react';
import { getMerchantType } from 'utils/merchant';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import CustomerInput from './CustomerInput';
import './index.css';
import MerchantInput from './MerchantInput';
import Summary from './Summary';

function GeneralInfo() {
  const form = Form.useFormInstance();
  const customer = Form.useWatch('customer', form);
  const merchant = Form.useWatch('merchant', form);

  return (
    <Steps.Step step={STEPS.GENERAL_INFO} summary={<Summary />}>
      <Row gutter={24} className="mb-2 mb-md-0">
        <Col xs={24} md={12} lg={9}>
          <OrderFormItem
            name="merchant"
            label="Select merchant"
            fullWidth
            rules={[{ required: true, message: 'Please select the merchant' }]}
          >
            <MerchantInput />
          </OrderFormItem>
        </Col>
        {merchant && (
          <Col xs={24} md={12} lg={10} className="my-auto pt-0 md:pt-5">
            <span>{getMerchantType(merchant)}</span>
          </Col>
        )}
      </Row>
      <Row gutter={24} className="mb-2 mb-md-0">
        <Col xs={24} md={12} lg={9}>
          <OrderFormItem
            name="customer"
            label="Select customer"
            fullWidth
            rules={[{ required: true, message: 'Please select the customer' }]}
          >
            <CustomerInput />
          </OrderFormItem>
        </Col>
        {customer && (
          <Col xs={24} md={12} lg={10} className="my-auto d-flex gap-3 pt-0 md:pt-5">
            <Typography>{customer.name}</Typography>
            <Typography.Text style={{ color: '#A7A9A9' }}>{customer.email}</Typography.Text>
          </Col>
        )}
      </Row>
    </Steps.Step>
  );
}

export default GeneralInfo;
