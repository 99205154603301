import React, {
  useCallback,
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

import {
  getModifierMinSelections,
  optionsHasChildren,
  isMultiSelect,
} from './utils';

export const modifierContext = createContext();

export default function ModifierProvider({ modifiers, children }) {
  const [stack, setStack] = useState([]);
  const [selectedModifiers, setSelectedModifiers] = useState({});
  const allOptions = useMemo(() => (modifiers || []).flatMap((m) => m.options), [modifiers]);
  const getModifier = useCallback((o) => modifiers.find((m) => m.id === o.modifier), [modifiers]);

  const getOptionKey = useCallback((o) => {
    const currentKey = `${o.modifier}-${o.id}`;
    const parentOption = allOptions.find((p) => p.modifiers_children?.includes(o.modifier));
    if (!parentOption) {
      return currentKey;
    }
    return `${getOptionKey(parentOption)}/${currentKey}`;
  }, [allOptions]);

  const getModifierKey = useCallback((m) => {
    const currentKey = m.id;
    const parentOption = allOptions.find((op) => op.modifiers_children?.includes(m.id));
    if (!parentOption) {
      return currentKey;
    }
    return `${getOptionKey(parentOption)}/${currentKey}`;
  }, [getOptionKey, allOptions]);

  useEffect(() => {
    setSelectedModifiers(
      getSelectedOptions({ allOptions, getModifier })
        .reduce((acc, op) => ({ ...acc, [getOptionKey(op)]: { ...op, qty: op.default_qty || 1 } }), {}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, getOptionKey]);

  const doesKeyMatchModifier = useCallback(
    ({ key, modifier }) => new RegExp(`${getModifierKey(modifier)}-[\\d]+$`).test(key),
    [getModifierKey],
  );

  const getTotalQty = useCallback(
    (modifier) => Object.entries(selectedModifiers)
      .filter(
        ([key, value]) => value && doesKeyMatchModifier({ key, modifier }),
      )
      .reduce((total, [_, { qty }]) => total + qty, 0),
    [doesKeyMatchModifier, selectedModifiers],
  );

  const getSelectedOption = useCallback(
    ({ option }) => selectedModifiers[getOptionKey(option)],
    [selectedModifiers, getOptionKey],
  );

  const hasSelectionsSatisfied = useCallback(
    (modifier) => {
      const minSelections = getModifierMinSelections(modifier);
      const totalQty = getTotalQty(modifier);
      return totalQty >= minSelections;
    },
    [getTotalQty],
  );

  const unSatisfiedModifiers = useMemo( () => {
    if (!modifiers || !modifiers.length) {
      return [];
    }
    const rootModifiers = modifiers.filter(m => !m.options_parents.length);
    const selectedModifierWithNested = Object.entries(selectedModifiers).filter( m => m[1].modifiers_children?.length);
    const mandatoryNestedModifierChildren = selectedModifierWithNested.map( selected => modifiers.find( m => m.id === selected[1].modifiers_children[0] ));
    return [...rootModifiers, ...mandatoryNestedModifierChildren].filter((m) => !hasSelectionsSatisfied(m));
    }, [modifiers, hasSelectionsSatisfied, selectedModifiers],
  );

  const pushIntoStack = useCallback((option) => {
    const modifier = getModifier(option);
    setStack((s) => ([...s, { modifier, option }]));
  }, [getModifier]);

  const handleChange = useCallback(
    ({ modifier, option, value }) => {
      const key = getOptionKey(option);

      if (value && optionsHasChildren(option)) {
        setStack((current) => [...current, { modifier, option }]);
      }

      let revalidateRequired = true;
      let selectedModifiersEntries = Object.entries({
        ...selectedModifiers,
        [key]: value,
      });

      while (revalidateRequired) {
        revalidateRequired = false;
        selectedModifiersEntries = selectedModifiersEntries.filter(
          ([currentOptionKey, currentOptionValue]) => {
            if (!currentOptionValue) {
              revalidateRequired = true;
              return false;
            }
            if (currentOptionKey === key) {
              return true;
            }

            // nested modifier
            if (currentOptionKey.split('/').length > 1) {
              const parentKey = currentOptionKey
                .split('/')
                .slice(0, -1)
                .join('/');
              // if parent has been removed, remove this as well
              if (!selectedModifiersEntries.some(([mk]) => mk === parentKey)) {
                revalidateRequired = true;
                return false;
              }
            }

            // remove other options for single-select option types
            if (
              !isMultiSelect(modifier) &&
              doesKeyMatchModifier({ key: currentOptionKey, modifier })
            ) {
              revalidateRequired = true;
              return false;
            }

            return true;
          },
        );
      }

      setSelectedModifiers(Object.fromEntries(selectedModifiersEntries));
    },
    [selectedModifiers, doesKeyMatchModifier, getOptionKey],
  );

  const isSelected = useCallback(
    ({ modifier, option }) => {
      const key = option ? getOptionKey(option) : getModifierKey(modifier);
      return Object.keys(selectedModifiers).some((k) => k.startsWith(key));
    },
    [getModifierKey, getOptionKey, selectedModifiers],
  );

  const getNormalizedSelectedModifiers = useCallback(() => {
    const keys = Object.keys(selectedModifiers);
    return Object.entries(selectedModifiers)
      // .filter(
      //   ([key]) => !keys.some((k) => k.length > key.length && k.includes(key)),
      // )
      .map(([_, value]) => value);
  }, [selectedModifiers]);

  const getSelectedModifiersTree = useCallback(() => {
    const keys = Object.keys(selectedModifiers);
    return Object.entries(selectedModifiers)
      .filter(
        ([key]) => !keys.some((k) => k.length > key.length && k.includes(key)),
      )
      .reduce(
        (acc, [key, { qty: quantity }]) => ({ ...acc, [key]: { quantity } }),
        {},
      );
  }, [selectedModifiers]);

  const getNestedOptions = useCallback((parentId) => {
    return modifiers.filter( m => m.options_parents.includes(parentId));
  }, [modifiers]);
  const value = useMemo(
    () => ({
      isSelected,
      getTotalQty,
      handleChange,
      pushIntoStack,
      selectedModifiers,
      getSelectedOption,
      unSatisfiedModifiers,
      hasSelectionsSatisfied,
      loading: modifiers == null,
      getSelectedModifiersTree,
      getNormalizedSelectedModifiers,
      getModifier,
      modifiers,
      getNestedOptions,
      setSelectedModifiers,
    }),
    [
      modifiers,
      isSelected,
      getTotalQty,
      handleChange,
      pushIntoStack,
      selectedModifiers,
      getSelectedOption,
      unSatisfiedModifiers,
      hasSelectionsSatisfied,
      getSelectedModifiersTree,
      getNormalizedSelectedModifiers,
      getModifier,
      getNestedOptions,
    ],
  );

  return (
    <modifierContext.Provider value={value}>
      {children}
    </modifierContext.Provider>
  );
}

function getSelectedOptions({ allOptions, getModifier }) {
  const selectedOptions = allOptions.filter((o) => o.default_qty > 0);
  let revalidateRequired;
  do {
    revalidateRequired = false;
    selectedOptions.forEach((o) => {
      const modifier = getModifier(o);
      if (modifier.options_parents.length === 0) {
        return;
      }
      const parentOptions = allOptions.filter((op) => modifier.options_parents.includes(op.id));
      const parentOption = parentOptions.find((op) => op.default_qty > 0) || parentOptions[0];
      if (!selectedOptions.some((sp) => sp.id === parentOption.id)) {
        selectedOptions.push(parentOption);
        revalidateRequired = true;
      }
    });
  } while (revalidateRequired);
  return selectedOptions;
}
