import { LoadingOutlined, PlusSquareOutlined } from '@ant-design/icons';
import {
  Col, Image, List, Row, Spin, Typography,
} from 'antd';
import { Colors } from 'assets/colors';
import { fallbackItemImage } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext, useState } from 'react';
import http from 'services/http';
import { getImage } from 'utils/image';
import ItemDetailModal from './ItemDetailModal';
import { getItemPrices } from './ItemDetailModal/item';
import ModifierProvider from './ItemDetailModal/ModifierProvider';
import ItemPrice from './ItemPrice';
import Menus from './Menus';
import SearchItemInput from './SearchItemInput';
import { mergeItems, sortItems } from './utils';

function MenuItems({ rerenderKey }) {
  const isMobile = useIsMobile();
  const { order, setOrderDetails, menuItems } = useContext(CallInOrderContext);
  const [loading, setLoading] = useState(false);
  const [showAddItemDetailModal, setShowAddItemDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  function getItemData(item) {
    setShowAddItemDetailModal(true);
    http.get(`v1/items/${item?.id}/`).then(({ data: { data } }) => {
      setSelectedItem(data);
    });
  }

  function addItemToSelectedList(item) {
    setOrderDetails({ items: sortItems(mergeItems({ items: order?.items, item })) });
  }

  return (
    <>
      <ModifierProvider modifiers={selectedItem?.modifiers}>
        <ItemDetailModal
          item={selectedItem}
          open={showAddItemDetailModal}
          onClose={() => setShowAddItemDetailModal(false)}
          handleAddItem={(item) => {
            addItemToSelectedList(item);
          }}
        />
      </ModifierProvider>

      <>
        <Row style={{ marginRight: isMobile ? 0 : 16 }}>
          <Col xs={24} md={10}><Menus loading={loading} setLoading={setLoading} /></Col>
          <Col xs={24} md={14}><SearchItemInput disabled={loading} key={rerenderKey} /></Col>
        </Row>
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: 'auto',
            marginTop: 8,
            paddingRight: isMobile ? 0 : 10,
          }}
        >
          {loading ? (
            <div className="flex justify-content-center align-items-center flex-column h-100">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              <Typography.Text
                style={{ color: '#949AA0', marginTop: 16 }}
              >
                Items are loading
              </Typography.Text>
            </div>
          ) : (
            menuItems?.filter((cat) => cat.items?.length > 0)?.map((cat) => (
              <List
                key={cat.id}
                header={<div>{cat.name}</div>}
                dataSource={cat?.items}
                style={{ paddingRight: 8 }}
                renderItem={(item) => (
                  <List.Item>
                    <Row
                      gutter={2}
                      style={{ width: '100%' }}
                    >
                      <Col xs={3} sm={4} className="m-auto">
                        <Image
                          width={isMobile ? 32 : 45}
                          height={isMobile ? 32 : 45}
                          style={{ borderRadius: 4, border: '1px solid #DDE1E4' }}
                          src={getImage({ data: item })}
                          fallback={fallbackItemImage}
                        />
                      </Col>
                      <Col xs={21} sm={20}>
                        <div className="flex flex-row justify-between items-center w-100">
                          <div className="flex flex-column justify-between items-start" style={{ width: '95%' }}>
                            <Typography.Text
                              style={{ width: '100%' }}
                              ellipsis={{
                                tooltip: item?.name,
                              }}
                            >
                              {item?.name}
                              {' '}
                            </Typography.Text>
                            <ItemPrice
                              price={getItemPrices({ item })?.price}
                              discountedPrice={getItemPrices({ item })?.discountedPrice}
                              style={{ paddingLeft: 14 }}
                            />
                          </div>
                          <div style={{ width: '5%' }}>
                            <PlusSquareOutlined
                              onClick={() => {
                                setSelectedItem(null);
                                getItemData(item);
                              }}
                              style={{ color: Colors.primary1 }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            ))
          )}
        </div>
      </>
    </>
  );
}

export default MenuItems;
