import {
  Alert, Button, Drawer, Flex, Input, Row, Space, Typography,
} from 'antd';
import Map from 'components/Map';
import useConfig from 'hooks/useConfig';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import SkeletonLoading from 'components/common/SkeletonLoading';
import { addAddressToCustomer } from 'api/customers';
import { EditOutlined } from '@ant-design/icons';
import { captureException } from 'utils/errors';
import { checkLocationIsSupported } from './requests';

const STEPS = {
  GET_LOCATION: 'GET_LOCATION',
  GET_INFO: 'GET_INFO',
};
function AddAddressModal({
  customerId,
  open,
  onClose,
  onSave,
  merchantId,
}) {
  const { defaultLat, defaultLong } = useConfig('general') || {};
  const [step, setStep] = useState(STEPS.GET_LOCATION);
  const [address, setAddress] = useState({ label: null, details: null, deliveryInstructions: null });
  const [loading, setLoading] = useState(false);
  const [saveAddressLoading, setSaveAddressLoading] = useState(false);
  const [location, setLocation] = useState({ lat: defaultLat, lng: defaultLong, isDefault: true });
  const isFirstSearch = useRef(true);
  const [outOfZone, setOutOfZone] = useState(false);

  useEffect(() => {
    setStep(STEPS.GET_LOCATION);
  }, [open]);

  const cleanupState = () => {
    setStep(STEPS.GET_LOCATION);
    setAddress({ label: null, details: null, deliveryInstructions: null });
    setLoading(false);
    setSaveAddressLoading(false);
    setLocation({ lat: defaultLat, lng: defaultLong });
    isFirstSearch.current = true;
    setOutOfZone(false);
  };
  const confirmLocation = () => {
    if (isFirstSearch.current) {
      isFirstSearch.current = false;
    }
    setStep(STEPS.GET_INFO);
  };

  const updateAddress = () => {
    const data = {
      title: address.label,
      address: location?.text,
      latitude: location?.lat,
      longitude: location?.lng,
      details: address.details,
      delivery_instructions: address.deliveryInstructions,
      customer: customerId,
    };

    setSaveAddressLoading(true);
    addAddressToCustomer(data)
      .then((res) => {
        onSave(res.data);
        onClose();
        cleanupState();
      })
      .catch((e) => {
        captureException(e);
      })
      .finally(() => setSaveAddressLoading(false));
  };

  useEffect(() => {
    const checkAddressZone = () => {
      const isDefault = location.lat === defaultLat && location.lng === defaultLong;
      if (location && !isDefault) {
        if ((location?.lat, location?.lng)) {
          setLoading(true);
          checkLocationIsSupported({ merchantId, lat: location?.lat, lng: location?.lng })
            .then(({ data }) => {
              setOutOfZone(!data.is_location_supported);
              if (data.is_location_supported && isFirstSearch.current) {
                confirmLocation();
              }
            })
            .catch(captureException)
            .finally(() => setLoading(false));
        }
      }
    };
    checkAddressZone();
  }, [location]);

  const isDisable = useMemo(() => {
    if (step === STEPS.GET_LOCATION) {
      return outOfZone || loading || !location?.lat || !location?.lng || !location?.text;
    }
    return saveAddressLoading;
  }, [location, outOfZone, saveAddressLoading, loading]);

  const createLabel = () => (step === STEPS.GET_LOCATION ? 'Confirm location' : 'Create');

  const handleBtnClick = () => (step === STEPS.GET_LOCATION ? confirmLocation : updateAddress);

  if (!open) {
    return null;
  }

  function handleUpdateAddressDetail(payload) {
    setAddress((adr) => ({ ...adr, ...payload }));
  }

  return (
    <Drawer
      title={
        step === STEPS.GET_LOCATION ? (
          'Choose Location'
        ) : (
          'Add Address'
        )
      }
      width={572}
      open={open}
      onClose={() => {
        onClose();
        cleanupState();
      }}

      footer={(
        <Flex justify="end" gap={16}>
          <Button
            key="back"
            onClick={() => {
              onClose();
              cleanupState();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleBtnClick()}
            type="primary"
            disabled={isDisable}
          >
            {createLabel()}
          </Button>
        </Flex>
      )}

    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {step === STEPS.GET_LOCATION ? (
          <>
            {isFirstSearch.current && (
              <Map.SearchInput
                hasCurrentLocationSelector={false}
                onChange={(event) => {
                  setLocation(event);
                }}
              />
            )}
            <Map
              center={location}
              onChange={setLocation}
              style={{ display: isFirstSearch.current ? 'none' : 'block' }}
            >
              <Map.FixedMarker />
              <Map.SearchInput hasCurrentLocationSelector={false} onChange={(event) => setLocation(event)} />
            </Map>

            <Flex vertical style={{ marginTop: isFirstSearch.current ? 28 : 0, marginLeft: isFirstSearch.current ? 8 : 0 }}>
              {loading && (
                <SkeletonLoading count={1} width="150px" />
              )}
              {!loading && outOfZone && (
                <Alert
                  message="Out of operation area. Please select another address."
                  type="warning"
                />
              )}
              {(!isFirstSearch.current && !loading && !outOfZone) && (
                <Typography.Text type="c-m" classes="content-add-info">
                  {location?.text}
                </Typography.Text>
              )}
            </Flex>
          </>
        ) : (
          <>
            <div style={{ pointerEvents: 'none' }}>
              <Map
                center={location}
                height={148}
                zoomControl={false}
                fullscreenControl={false}
              >
                <Map.FixedMarker />
              </Map>
            </div>
            <Flex justify="space-between" align="center">
              <Typography.Text type="c-m" classes="content-add-info">
                {location?.text}
              </Typography.Text>
              <Button icon={<EditOutlined />} onClick={() => setStep(STEPS.GET_LOCATION)}>Adjust pin</Button>
            </Flex>

            <Row>Address label</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="label"
                value={address.label}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ label: value })}
                placeholder="ex. Home"
              />
            </Row>
            <Row>Details</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="detail"
                value={address.details}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ details: value })}
                placeholder="Apt, floor, suite, etc"
              />
            </Row>
            <Row>Instructions for delivery</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="code"
                value={address.deliveryInstructions}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ deliveryInstructions: value })}
                placeholder="Example: Please knock instead of using the doorbell"
              />
            </Row>
          </>
        )}
      </Space>
    </Drawer>
  );
}

export default AddAddressModal;
