import { Radio, Space, Tooltip } from 'antd';
import React from 'react';

function PaymentMethods({ value, onChange, paymentMethods }) {
  const setPayment = ({ target: { value } }) => {
    const paymentMethod = paymentMethods.find((method) => method.id === value);
    onChange(paymentMethod);
  };
  return (
    <Radio.Group value={value?.id} onChange={setPayment}>
      <Space direction="vertical">
        {paymentMethods?.map((pm) => (
          <Tooltip title={pm.description} key={pm.id}>
            <Radio value={pm.id}>{pm.title}</Radio>
          </Tooltip>
        ))}
      </Space>
    </Radio.Group>
  );
}
export default PaymentMethods;
