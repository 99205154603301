import { Col, Form, Row } from 'antd';
import { PaymentTypes } from 'constants/index';
import React from 'react';
import usePaymentMethods from './usePaymentMethods';
import CustomPaymentForm from './CustomPaymentForm';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import PaymentMethods from './PaymentMethods';
import Summary from './Summary';
import UserCards from './UserCards';

function PaymentInfo() {
  const {
    paymentMethods,
  } = usePaymentMethods();
  const payment_method = Form.useWatch('payment_method');
  return (
    <Steps.Step step={STEPS.PAYMENT_INFO} summary={<Summary />}>
      <Row>
        <Col xs={24} lg={7}>
          <OrderFormItem name="payment_method" label="Pay with" fullWidth rules={[{ required: true, message: 'Please select a payment method!' }]}>
            <PaymentMethods paymentMethods={paymentMethods} />
          </OrderFormItem>
        </Col>

        <Col xs={24} lg={17}>
          {payment_method?.type === PaymentTypes.CARD && (
            <OrderFormItem name={['customPaymentMeta', 'card_id']} fullWidth rules={[{ required: true, message: 'Please add a credit card!' }]}>
              <UserCards />
            </OrderFormItem>
          )}

          {payment_method?.meta && (
            <CustomPaymentForm meta={payment_method.meta} />
          )}
        </Col>
      </Row>
    </Steps.Step>
  );
}
export default PaymentInfo;
