import React from 'react';
import { Form, Input, Select } from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import FormItem from 'components/FormItem';

const OMNI_TYPE = [
  { value: 'Toast', label: 'Toast' },
  { value: 'Olo', label: 'Olo' },
  { value: 'Square', label: 'Square' },
];

function OmniMerchantForm() {
  const isMobile = useIsMobile();

  return (
    <>
      <FormItem
        name="integration"
        label="Platform"
        rules={[{ required: true, message: 'Please select a platform' }]}
      >
        <Select
          options={OMNI_TYPE}
          placeholder="Select Omni Type"
        />
      </FormItem>
      <FormItem
        name="url"
        label="URL"
        rules={[
          { required: true, message: 'Please enter the URL' },
          { type: 'url', message: 'Please enter a valid URL' },
        ]}
      >
        <Input
          type="url"
          placeholder="Merchant URL (ex: https://test-merchant.com)"
        />
      </FormItem>
    </>
  );
}

export default OmniMerchantForm;
