import {
  Button, Col, Form, Input, Modal, Row,
} from 'antd';
import { createCustomer } from 'api/customers';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';

function CreateUserModal({ open, onClose, onSuccess }) {
  const [phone, setPhone] = useState(null);
  const [countryDialCode, setCountryDialCode] = useState('+1');

  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();
  const handleChangePhone = (value) => {
    if (!value) {
      setPhone(null);
    } else {
      setPhone(value);
    }
  };
  const onSubmit = ({ phoneNumber, name, email }) => {
    setSaving(true);
    createCustomer({ phone: phoneNumber, name, email })
      .then((res) => {
        if (res.status === 'success') {
          {
            onSuccess({
              customerId: res.data.id,
              value: phoneNumber,
              label: name,
              name,
              email,
            });
            onClose(false);
            form.resetFields();
          }
        }
      })
      .catch((e) => {
        captureException(e);
        errorHandling(e.response);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      width={575}
      open={open}
      title="Create user"
      onCancel={() => {
        form.resetFields();
        onClose(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            onClose(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          loading={saving}
          key="submit"
          form="create-customer"
          htmlType="submit"
          type="primary"
        >
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="create-customer"
        name="create-customer"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(fail) => {
          console.log('fail', fail);
        }}
      >
        <Row justify="space-between">
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              validateFirst
              initialValue={phone}
              name="phoneNumber"
              label="Phone number"
            >
              <PhoneInput
                country="ca"
                buttonClass="phone-country"
                value={phone}
                inputClass="input"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{ width: '100%' }}
                onChange={(value, country) => {
                  handleChangePhone(value);
                  setCountryDialCode(country?.dialCode);
                }}
                placeholder="Enter phone number"
                autofocus
                preferredCountries={['us', 'ca']}
                enableSearch
                isValid={(value, country) => {
                  if (!country?.dialCode) {
                    return 'Please select a country';
                  } if (phone?.length <= countryDialCode?.length) {
                    return 'Please enter phone number';
                  }
                  return true;
                }}
                regions={['america', 'europe']}
                countryCodeEditable={false}
                masks={{
                  pr: '(...) ...-....',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ paddingLeft: 12 }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input
                style={{ height: 35 }}
                placeholder="Enter the customer name..."
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input
                style={{ height: 35 }}
                placeholder="Enter customer email..."
                type="email"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateUserModal;
