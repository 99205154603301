import React, { useState } from 'react';
import {
  Flex, Input, Select, Divider, Typography,
} from 'antd';
import FormItem from 'components/FormItem';
import GeneralForm from './GeneralForm';

function CateringForm({ merchant }) {
  const [importMenu, setImportMenu] = useState(false);
  return (
    <Flex vertical className="box">
      <FormItem label="Menu">
        <Select
          value={importMenu ? 'yes' : 'no'}
          onChange={(value) => setImportMenu(value === 'yes')}
          options={[
            { value: 'no', label: 'Create menu from scratch' },
            { value: 'yes', label: 'Import from another platform' },
          ]}
        />
      </FormItem>

      {importMenu ? (
        <ImportMenuForm merchant={merchant} />
      ) : (
        <GeneralForm merchantDetail={merchant} />
      )}
    </Flex>
  );
}

function ImportMenuForm({ merchant }) {
  return (
    <>
      <FormItem
        label="URL"
        name="url"
        rules={[
          { type: 'url', message: 'Please enter a valid URL' },
          { required: true, message: 'Please enter the URL' },
        ]}
      >
        <Input
          placeholder="Merchant URL (ex: https://test-merchant.com)"
        />
      </FormItem>
      <FormItem
        label="Email"
        name="restaurant_email"
        rules={[
          { type: 'email', message: 'Please enter a valid email address' },
          { required: true, message: 'Please enter the email' },
        ]}
      >
        <Input
          type="email"
          placeholder="email"
        />
      </FormItem>
    </>
  );
}

export default CateringForm;
