import React, { useContext } from 'react';
import {
  Form, Row, Space, Typography,
} from 'antd';
import { modifierContext } from '../ModifierProvider';
import {
  getModifierSelectionsText,
  isAutoApplied,
  isMultiSelect,
} from '../utils';
import MultiSelectModifier from './MultiSelectModifier';
import SingleSelectModifier from './SingleSelectModifier';

function Modifier({ modifier, isNested= false }) {
  const { hasSelectionsSatisfied } = useContext(modifierContext);
  const isSatisfied = hasSelectionsSatisfied(modifier);

  const Control = isMultiSelect(modifier)
    ? MultiSelectModifier
    : SingleSelectModifier;
  const disabled = isAutoApplied(modifier);
  return (
    <Form>
      <Space className="900" direction="vertical" style={{ width: '100%', paddingLeft: isNested ? 24 : 0, paddingTop: isNested ? 8 : 0 }}>
        {!isNested && (
          <div className="flex justify-content-between">
            <Typography.Text strong type={isSatisfied ? 'primary' : 'danger'}>
              {modifier.title}
              {!isSatisfied && !isAutoApplied(modifier) && (
                <Typography.Text type="secondary" className="ml-1 text-xs">
                  (Required)
                </Typography.Text>
              )}
            </Typography.Text>
          </div>
        )}

        <Row>
          {!isAutoApplied(modifier) && (
            <Typography.Text type="secondary" className={`text-xs ${!isSatisfied && 'text-red-700'}`}>
              {getModifierSelectionsText(modifier)}
            </Typography.Text>
          )}
        </Row>

        <Control modifier={modifier} disabled={disabled} />
      </Space>
    </Form>
  );
}

export default Modifier;
