import { Form, InputNumber } from 'antd';
import ItemsInput from 'components/ItemsInput';
import React, { useContext } from 'react';
import { StepsContext } from '../Steps/StepsProvider';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import { useIsCatering } from '../useIsCatering';
import Summary from './Summary';

function ItemsInfo() {
  const isCatering = useIsCatering();
  const { isActiveStep } = useContext(StepsContext);
  const isActive = isActiveStep(STEPS.ITEMS.id);
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');
  const items = form.getFieldValue('items');
  const getTotalCount = () => {
    if (!isActive && items?.length > 0) {
      let qty = 0;
      items.map((item) => qty += item.qty);
      return `(${qty})`;
    }
    return '';
  };

  return (
    <Steps.Step step={{ ...STEPS.ITEMS, title: `${STEPS.ITEMS.title} ${getTotalCount()}` }} summary={<Summary />}>
      <OrderFormItem
        name="headcount"
        label="Headcount"
        rules={[{ required: isCatering, message: 'Please select the merchant' }]}
        hidden={!isCatering}
      >
        <InputNumber addonAfter="People" min={1} />
      </OrderFormItem>
      <OrderFormItem name="items" fullWidth rules={[{ required: true }]}>
        <ItemsInput
          merchant={merchant}
          isEditMode={false}
          isCatering={isCatering}
        />
      </OrderFormItem>
    </Steps.Step>
  );
}

export default ItemsInfo;
