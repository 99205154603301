import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/common/BadgeLabel';
import React from 'react';

export const getOrderType = (order) => {
  return order?.has_delivery ? (
    <BadgeLabel
        label="Delivery"
        leadingIcon={<DeliveryIcon />}
        classes="type-size"
      />
  ) : (
    <BadgeLabel
        label="Pick Up"
        leadingIcon={<PickupIcon />}
        classes="type-size"
      />
  );

};
