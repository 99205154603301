import {
  Button,
  Col, Drawer, Flex, Row,
} from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import Cart from './Cart';
import MenuItems from './MenuItems';

function AddOrderItemOverlay({
  open, onClose, onAdd, merchantId, nested, value, onChange, isCatering,
}) {
  const isMobile = useIsMobile();
  const [rerenderKey, setRerenderKey] = useState(0);
  const handleClose = () => {
    onClose();
    setRerenderKey(rerenderKey + 1);
  };
  if (!open)
    return null;
  return (
    <Drawer
      title="Add Item"
      placement="right"
      onClose={handleClose}
      open={open}
      size="large"
      width={nested ? 600 : 720}
      styles={{ body: { padding: !isMobile ? '0 0 0 24px' : '0 0 0 16px', overflow: 'hidden' } }}
      footer={(
        <Flex gap={8} justify="flex-end">
          <Button
            key="confirm"
            type="primary"
            onClick={handleClose}
          >
            Confirm
          </Button>
        </Flex>
      )}
    >
      <Row style={{ height: '100vh' }}>
        <Col
          xs={24}
          md={14}
          style={{
            borderRight: isMobile ? 0 : '1px solid #DDE1E4',
            paddingTop: isMobile ? 16 : 24,
            overflow: 'scroll',
            height: '88vh',
          }}
        >
          <MenuItems
            rerenderKey={rerenderKey}
            merchantId={merchantId}
            onAdd={(item) => {
              onAdd({
                id: uuid(),
                item_related: item,
                qty: 1,
                isNew: true,
                real_price: Number(item.real_price || item.value),
              });
            }}
            isCatering={isCatering}
          />
        </Col>
        {!isMobile && (
          <Col
            md={10}
            style={{
              paddingTop: isMobile ? 8 : 12, paddingRight: !isMobile ? 24 : 16, overflow: 'scroll', height: '85vh',
            }}
          >
            <Cart value={value} onChange={onChange} isCatering={isCatering} />
          </Col>
        )}
      </Row>
    </Drawer>
  );
}

export default AddOrderItemOverlay;
