import { Button, Col, Flex, InputNumber, Row, Space } from 'antd';
import { TagSelector } from 'components/DietaryTags/TagSelector';
import React, { useState } from 'react';
import OptionSelector from './OptionSelector';

function OptionComponent({ onSave, onAddNested, selectedOptions }) {
  const [currentOption, setCurrentOption] = useState();
  return (
    <Row gutter={8} className="mt-2">
      <Col span={15}>
        <Flex vertical gap={8}>
          <OptionSelector
            option={currentOption?.option}
            setOption={(option) => setCurrentOption({ ...currentOption, option })}
            selectedOptions={selectedOptions}
          />
          <Flex gap={8}>
            <InputNumber
              type="number"
              placeholder="Price"
              prefix="$"
              controls={false}
              onChange={(real_price) => setCurrentOption({ ...currentOption, real_price })}
            />
            <TagSelector
              onChangeDetail={(values) => setCurrentOption({ ...currentOption, dietary_tags: values })}
              maxTagCount={2}
            />
          </Flex>
        </Flex>
      </Col>
      <Col span={9} style={{ alignSelf: 'end' }}>
        <Space>
          <Button onClick={onAddNested}>
            Add nested
          </Button>
          <Button type="primary" onClick={() => onSave(currentOption)} disabled={!currentOption?.option?.value}>
            Save
          </Button>
        </Space>

      </Col>
    </Row>
  );
}

export default OptionComponent;
