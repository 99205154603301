import { Form } from 'antd';
import { getMerchantJustById } from 'api/merchants';
import {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { captureException } from 'utils/errors';
import { PaymentTypes } from 'constants/index';
import { fetchUserCards } from './requests';

function usePaymentMethods() {
  const payment_method = Form.useWatch('payment_method');
  const customer = Form.useWatch('customer');
  const merchant = Form.useWatch('merchant');
  const [stripeKey, setStripeKey] = useState('');
  const [cardsLoading, setCardsLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const getMerchantDetail = () => {
    if (merchant?.id) {
      getMerchantJustById(merchant.id).then((res) => {
        setStripeKey(res?.data?.stripe_api_public_key);
      });
    }
  };

  useEffect(() => {
    getMerchantDetail();
  }, [merchant]);

  const getUserCards = (paymentMethod) => {
    setCardsLoading(true);
    fetchUserCards( { userId: customer.id, paymentMethod }).then((res) => {
      if (res?.data) {
        setCards(res?.data);
        setCardsLoading(false);
      }
    }).catch((e) => {
      captureException(e);
      setCardsLoading(false);
    });
  };

  useEffect(() => {
    if (payment_method?.type === PaymentTypes.CARD && customer.id) getUserCards();
  }, [customer, payment_method]);

  const paymentMethods = useMemo(() => {
    const methods = [
      {
        id: PaymentTypes.CARD,
        title: 'Credit card',
        type: PaymentTypes.CARD,
      },
      // {
      //   id: PaymentTypes.PAID_BY_MERCHANT,
      //   title: 'Paid by merchant',
      //   type: PaymentTypes.PAID_BY_MERCHANT,
      // },
    ];
    methods.push(...(merchant?.config?.custom_payments || [])?.map((pm) => ({
      ...pm,
      type: PaymentTypes.CUSTOM,
    })));
    return methods;
  }, [merchant, cards]);

  const reFetch = useCallback((paymentMethod) => {
    getUserCards(paymentMethod);
  }, [getUserCards]);

  return useMemo(
    () => ({
      paymentMethods,
      loading: cardsLoading,
      reFetch,
      stripeKey,
      cards,
    }),
    [
      paymentMethods,
      cardsLoading,
      reFetch,
      stripeKey,
      cards,
    ],
  );
}

export default usePaymentMethods;
