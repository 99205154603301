import React from 'react';

function SpicyIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.33334" y="1.3335" width="13.3333" height="13.3333" rx="4" fill="black" fillOpacity="0.45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2858 5.67271C10.3565 5.39622 10.5117 5.12089 10.7296 4.89017C11.0341 4.56779 11.4208 4.375 11.7778 4.375C11.9734 4.375 12.132 4.20711 12.132 4C12.132 3.79289 11.9734 3.625 11.7778 3.625C11.1904 3.625 10.6326 3.93221 10.2287 4.35983C9.90003 4.70788 9.64666 5.16387 9.56374 5.65687C9.21499 5.72585 8.89114 5.9048 8.63673 6.17417C8.30464 6.52581 8.11807 7.00272 8.11807 7.5C8.11807 8.32407 7.29723 9.125 6.11112 9.125C4.92501 9.125 4.10418 8.32407 4.10418 7.5C4.10418 7.29289 3.94561 7.125 3.75001 7.125C3.55441 7.125 3.39584 7.29289 3.39584 7.5C3.39584 8.66032 3.83117 9.77312 4.60606 10.5936C5.38095 11.4141 6.43193 11.875 7.52779 11.875C8.62365 11.875 9.67462 11.4141 10.4495 10.5936C11.2244 9.77312 11.6597 8.66032 11.6597 7.5C11.6597 7.00272 11.4732 6.52581 11.1411 6.17417C10.9042 5.92337 10.6071 5.75096 10.2858 5.67271Z" fill="black" fillOpacity="0.45" />
    </svg>

  );
}
export default SpicyIcon;
