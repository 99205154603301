import { Typography } from 'antd';
import SimpleModal from 'components/SimpleModal';
import React from 'react';

function EditConfirmation(
  {
    open, setOpen, onConfirmEdit,
  },
) {
  return (
    <SimpleModal
      title="Edit Confirmation"
      open={open}
      setOpen={setOpen}
      onSubmit={onConfirmEdit}
      onCancel={() => setOpen(false)}
    >
      <Typography.Text>
        By confirming this action, changes will be applied, potentially affecting and removing related data.
      </Typography.Text>
    </SimpleModal>
  );
}

export default EditConfirmation;
