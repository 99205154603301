import {
  Flex,
} from 'antd';
import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import withGuard from 'components/withGuard';
import { AdminContext } from 'constants/contexts';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from 'utils/errors';
import {
  getAvgNps, getOverviews, getReviews, getTopMerchants,
} from '../../api/analytics';
import { useTimer } from '../../hooks/useTimer';
import { getData } from '../../utils';

import Demos from './Demos';
import Overview from './Overview';
import Reviews from './Reviews';
import { StyledWrapper } from './styles';
import TopMerchants from './TopMerchants';

const Dashboard = React.memo(() => {
  const [sec, reset] = useTimer(60);
  const [avgNps, setAvgNps] = useState(null);
  const [overview, setOverview] = useState(null);
  const [topMerchants, setTopMerchants] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const { organizationId } = useContext(AdminContext);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const getApisData = (withReload) => {
    if (!organizationId) return;

    if (withReload) {
      setLoading(true);
    }
    const getOverviewsData = getOverviews(
      null,
      null,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    const getTopMerchantsData = isAdmin ? getTopMerchants(
      null,
      null,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) : Promise.resolve({ 'top-merchants': [] });
    const getReviewsData = isAdmin ? getReviews(
      null,
      null,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) : Promise.resolve({ reviews: [] });

    const getAvgNpsData = isAdmin ? getAvgNps(
      moment().subtract(14, 'd').format('YYYY-MM-DD'),
      null,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) : Promise.resolve({ nps_avg: null });

    Promise.all([
      getOverviewsData,
      getTopMerchantsData,
      getReviewsData,
      getAvgNpsData,
    ])
      .then((resArr) => {
        setOverview(getData(resArr[0])?.overview);
        setTopMerchants(getData(resArr[1])['top-merchants']);
        setReviews(getData(resArr[2]).reviews);
        setAvgNps(getData(resArr[3]).nps_avg);
        if (withReload) {
          setLoading(false);
        }
      })
      .catch((e) => {
        captureException(e);
        if (withReload) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getApisData(true);
  }, [organizationId]);

  useEffect(() => {
    if (reset) {
      getApisData();
    }
  }, [reset]);

  return (
    <Layout header={(
      <Layout.Header
        title="Dashboard"
        extra={[
          <Flex gap={6} align="center" style={{ color: '#42494c80', fontWeight: 700 }}>
            <ClockIcon />
            Update in
            <span>
              {sec}
              {' '}
              s
            </span>
          </Flex>,
        ]}
      />
    )}
    >
      <StyledWrapper>
        <div className="title-row flex-row">
          {/* Overview */}
          {loading ? (
            <div className="loading">
              <Loading />
            </div>
          ) : (
            <>
              <Overview overview={overview} />
              {
                isAdmin && (
                  <div className="bottom">
                    <TopMerchants topMerchants={topMerchants} />
                    <Reviews reviews={reviews} avgNps={avgNps} />
                  </div>
                )
              }
            </>
          )}
        </div>
        {!isAdmin && (
          <Demos />
        )}
      </StyledWrapper>
    </Layout>
  );
});

export default withGuard(Dashboard, [], { redirect: true });
