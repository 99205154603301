import {
  Col, Form, Input, Row,
} from 'antd';
import SwitchInput from 'components/SwitchInput';
import React from 'react';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';
import OrderFormItem from '../OrderFormItem';

function CustomDeliveryFee() {
  const form = Form.useFormInstance();
  const hasDeliveryFee = Form.useWatch('has_delivery_fee');
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const has_delivery = form.getFieldValue('has_delivery');

  if (!isAdmin) {
    return null;
  }
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8} lg={5} className="d-flex align-items-center">
        <OrderFormItem name="has_delivery_fee" noStyle boolean fullWidth>
          <SwitchInput label="Custom delivery fee" direction="horizontal" disabled={!has_delivery} />
        </OrderFormItem>
      </Col>
      {hasDeliveryFee && (
        <Col xs={24} md={6} lg={4}>
          <OrderFormItem name="delivery_fee" noStyle>
            <Input suffix="$" type="number" />
          </OrderFormItem>
        </Col>
      )}
    </Row>
  );
}
export default CustomDeliveryFee;
