import {
  Button, Col, Flex,
  List,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

function Demos() {
  const DEMOES = [
    { id: 'clzh9e8vf1bje9x77ava0a99l', title: 'Menu Management' },
    { id: 'clzh7we7q1ap39x77v8rnbzgp', title: 'Merchant Report' },
  ];
  const [index, setIndex] = useState(0);
  const demo = DEMOES[index];
  function handleChangeVideo(step) {
    setIndex((prev) => prev + step);
  }
  return (
    <div>
      <Typography.Title level={3} className="mb-0">
        Tutorials
      </Typography.Title>
      <Row gutter={24}>
        <Col xs={24} md={6} className="py-4">
          <List
            dataSource={DEMOES}
            style={{
              height: 'calc(100% - 45px)',
              marginLeft: -8,
              overflowY: 'auto',
            }}
            className="sm:block hidden"
            renderItem={(d, i) => (
              <List.Item>
                <Button
                  type="link"
                  style={{ color: i === index ? undefined : 'grey' }}
                  onClick={() => setIndex(i)}
                >
                  { i + 1}
                  .
                  {' '}
                  {d.title }
                </Button>
              </List.Item>
            )}
          />
          <Flex justify="space-between" wrap="wrap" gap={8} className="mt-2">
            <Button disabled={index === 0} onClick={() => handleChangeVideo(-1)}>Previous</Button>
            <Button disabled={index === DEMOES.length - 1} onClick={() => handleChangeVideo(+1)}>Next</Button>
          </Flex>
        </Col>
        <Col xs={24} md={18}>
          <Demo {...demo} />
        </Col>
      </Row>

    </div>
  );
}
function Demo({ id, title }) {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setOpacity(0);
  }, [id]);
  return (
    <div style={{
      position: 'relative',
      boxSizing: 'content-box',
      width: '100%',
      aspectRatio: '1.77',
      padding: '40px 0 40px 0',
      opacity,
      transition: 'opacity 1s',
    }}
    >
      <iframe
        title={title}
        src={`https://app.supademo.com/embed/${id}`}
        onLoad={() => setOpacity(1)}
        loading="lazy"
        allow="clipboard-write"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
}

export default Demos;
