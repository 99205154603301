import DietaryTags from 'components/DietaryTags';
import React from 'react';
import { DownOutlined, UpOutlined, } from '@ant-design/icons';
import { Flex, Space, Typography } from 'antd';
import ItemPrice from '../AddOrderItemOverlay/ItemPrice';
import { hasOptionChildren } from './utils';

function OptionRow({ option, children, showDiscountPercentage = true, showChildren }) {
  const hasChildren = hasOptionChildren(option);
  return (
    <Flex vertical gap={8} >
      <div className="flex align-items-center justify-content-center flex-grow-1">
        {children}
        <Flex justify="space-between" align="center" className="w-full">
          <Space className="min-w-0 flex-shrink-0">
            <Typography.Text>
              {option?.title}
            </Typography.Text>
            <DietaryTags tags={option.dietary_tags} maxCount={3} hasTagStyle={false} />
          </Space>

          <Flex justify="end" gap={8} className="flex-shrink-1">
            <ItemPrice price={option.price} discountedPrice={option.discountedPrice} showZero showPercentage={showDiscountPercentage} />
            {hasChildren &&
              (showChildren ?
                  <UpOutlined style={{ fontSize: 12 }} /> :
                  <DownOutlined style={{ fontSize: 12 }} />
              )
            }
          </Flex>
        </Flex>
      </div>
    </Flex>

  );
}

export default OptionRow;
