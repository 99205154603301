import { DollarOutlined } from '@ant-design/icons';
import {
  Flex, Form, Space, Typography,
} from 'antd';
import React from 'react';

function Summary() {
  const form = Form.useFormInstance();
  const payment_method = form.getFieldValue('payment_method');
  return (
    <Flex gap={32}>
      <Space size={12}>
        <DollarOutlined style={{ fontSize: 20 }} />
        <Typography>
          Paying with
          {' '}
          {payment_method?.title}
        </Typography>
      </Space>
    </Flex>
  );
}
export default Summary;
