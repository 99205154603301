import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Drawer, message } from 'antd';
import StripeForm from './StripeForm';

function StripePaymentModal({
  open, onClose, reloadCards, title = 'Add credit card', subtitle, onBeforeSubmit, container: StripeContainer, containerProps,
}) {
  return (
    <Drawer
      open={open}
      title={title}
      width={572}
      onClose={onClose}
    >
      <StripeContainer {...containerProps}>
        <PaymentModal onClose={onClose} subtitle={subtitle} reloadCards={reloadCards} onBeforeSubmit={onBeforeSubmit} />
      </StripeContainer>
    </Drawer>
  );
}

function PaymentModal({
  onClose, subtitle, onBeforeSubmit, reloadCards,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    if (onBeforeSubmit) {
      await onBeforeSubmit();
    }
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });
    if (error) {
      setLoading(false);
      message.error(error.message || 'Something went wrong on adding card!');
    } else {
      reloadCards(setupIntent.payment_method);
      onClose();
    }
  };
  return (
    <StripeForm onSubmit={onSubmit} loading={loading} onClose={onClose} title={subtitle} />
  );
}

export default StripePaymentModal;
