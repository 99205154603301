import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import { Space, Switch, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function SwitchInput({
  label, description, direction, ...props
}) {
  const isMobile = useIsMobile();
  return (
    <Space align="start" size={8} direction={direction || (isMobile ? 'vertical' : 'horizontal')}>
      <Switch
        type="number"
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        {...props}
      />
      <Space.Compact direction="vertical">
        <Typography>{label}</Typography>
        {description && <Typography.Text type="secondary">{description}</Typography.Text>}
      </Space.Compact>
    </Space>
  );
}

export default SwitchInput;
