import { Divider, Form } from 'antd';
import { fetchMenus } from 'components/ItemsInput/AddOrderItemOverlay/requests';
import { StepsContext } from '../Steps/StepsProvider';
import React, { useContext } from 'react';
import { captureException } from 'utils/errors';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import { useIsCatering } from '../useIsCatering';
import OrderType from './OrderType';
import RecipientInfo from './RecepientInfo';
import SchedulingInput from './SchedulingInput';
import Summary from './Summary';

function DeliveryInfo() {
  const isCatering = useIsCatering();
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');
  const { setLoading } = useContext(StepsContext);

  return (
    <Steps.Step step={STEPS.DELIVERY_INFO} summary={<Summary />}>
      <OrderType />
      {isCatering && <RecipientInfo />}
      {(isCatering || merchant?.has_scheduling_order) && (
        <>
          <Divider/>
          <OrderFormItem
            name="scheduled_for"
            label="Scheduling time"
            fullWidth
            validateTrigger="onBlur"
            rules={[
              { required: isCatering },
              {
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    setLoading(true);

                    fetchMenus({ merchantId: merchant.id, scheduledTime: value}).then((res) => {
                      return resolve('');
                      setLoading(false);
                    }, (error) => {
                      if (
                        error &&
                        error.response?.data?.title === 'RestaurantNotAvailableException'
                      ) {
                        return reject(error.response?.data?.detail);
                      } else {
                        captureException(error);
                      }

                    }).finally(() => setLoading(false));
                  });
                },
              }]}>
            <SchedulingInput isCatering={isCatering} merchantId={merchant.id}/>
          </OrderFormItem>
        </>
      )}
    </Steps.Step>
  );
}

export default DeliveryInfo;
