import React, { useMemo } from 'react';
import {
  Divider, Radio, Space, Typography,
} from 'antd';
import OptionRow from './OptionRow';

function ItemSizes({
  item, value, onChange, isCatering,
}) {
  const itemSizes = useMemo(() => {
    if (!item?.sizes) {
      return [];
    }
    return item?.sizes
      .filter((t) => t.price)
      .map((size) => ({
        ...size,
        title: (
          <>
            {size.name}
            {isCatering && (
              <Typography.Text className="text-xs font-normal text-[#2B3235] ml-2">
                Serves
                {' '}
                {size.serving_size ? `${size.serving_size} people` : '1 person'}
              </Typography.Text>
            )}
          </>
        ),
        price: size.marked_up_price,
        discountedPrice: size.discounted_marked_up_price,
      }));
  }, [item]);

  if (!value && itemSizes[0]) {
    onChange(itemSizes[0].position);
  }

  if (itemSizes.length < 2) {
    return null;
  }
  return (
    <Space direction="vertical" className="w-full">
      <Typography.Text strong>Size</Typography.Text>
      <Radio.Group
        onChange={({ target }) => {
          const selectedType = itemSizes.find(
            (type) => type.position === target.value,
          );
          if (selectedType) {
            onChange(selectedType.position);
          }
        }}
        value={value}
        className="w-full"
      >
        {itemSizes.map((itemType, index) => (
          <div key={itemType?.id}>
            <Radio value={itemType?.position} className="omc-select-row">
              <OptionRow option={itemType} showDiscountPercentage={false} />
            </Radio>
            {(index !== itemSizes.length - 1) && <Divider className="my-3" />}
          </div>
        ))}
      </Radio.Group>
    </Space>
  );
}

export default ItemSizes;
