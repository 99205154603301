import {
  Flex, Form, Space, Typography,
} from 'antd';
import SimpleModal from 'components/SimpleModal';
import React from 'react';
import { priceTransformer } from 'utils';
import { useIsCatering } from './useIsCatering';

function EstimatedInfoModal({
  open, setOpen, submitOrder, data,
}) {
  const form = Form.useFormInstance();
  const {
    headcount,
    has_delivery,
    payment_method,
    recipient,
    recipient_name,
    recipient_last_name,
    recipient_phone,
    customer,
  } = form.getFieldsValue();
  const isCatering = useIsCatering();

  const priceRow = ({ title, price, style = { color: '#5A5F62' } }) => (
    <Flex justify="space-between">
      <Typography.Text style={style}>{title}</Typography.Text>
      <Typography.Text style={style}>{priceTransformer(price)}</Typography.Text>
    </Flex>
  );

  return (
    <SimpleModal
      title="Confirming order"
      open={open}
      setOpen={setOpen}
      onSubmit={submitOrder}
    >
      {isCatering && (
        <Flex vertical className="mb-8">
          <Typography.Title level={4}>
            Order instructions
          </Typography.Title>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Headcount</Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {headcount}
              {' '}
              people
            </Typography.Text>
          </Flex>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Recipient info</Typography.Text>
            <Space>
              <Typography.Text style={{ color: '#5A5F62' }}>
                {recipient === 'other' ? `${recipient_name} ${recipient_last_name}` : customer?.name}
              </Typography.Text>
              <Typography.Text style={{ color: '#5A5F62' }}>
                {recipient === 'other' ? recipient_phone : customer?.value}
              </Typography.Text>
            </Space>
          </Flex>
        </Flex>
      )}
      <Flex vertical className="mb-8">
        <Typography.Title level={4}>
          Order summary
        </Typography.Title>

        {priceRow({ title: 'Subtotal', price: data?.food_value })}
        {priceRow({ title: 'Delivery fee', price: data?.delivery_fare })}
        {priceRow({ title: 'Service fee', price: data?.service_fee })}
        {has_delivery && priceRow({ title: 'Tip', price: data?.tip })}
        {priceRow({ title: 'Adjustment', price: data?.adjustment_amount })}
        {priceRow({ title: 'Taxes', price: data?.tax })}
        {priceRow({
          title: 'Total',
          price: data?.total_value,
          style: {
            color: 'black', fontSize: '16px', fontWeight: '700', marginTop: 16,
          },
        })}
        {isCatering && priceRow({ title: 'Total per person', price: (data?.total_value / headcount) })}
      </Flex>

      <Flex vertical>
        <Typography.Title level={4}>
          Payment summary
        </Typography.Title>
        <Flex justify="space-between">
          <Typography.Text style={{ color: '#5A5F62' }}>Method</Typography.Text>
          <Typography.Text style={{ color: '#5A5F62' }}>{payment_method?.title}</Typography.Text>
        </Flex>
      </Flex>
    </SimpleModal>
  );
}

export default EstimatedInfoModal;
