import { Button, Drawer, Flex } from 'antd';
import { ModifierContext } from 'containers/MenuManagement/context';
import useIsMobile from 'hooks/useIsMobile';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from 'utils/errors';
import useFetchedData from 'hooks/useFetchedData';
import Loading from 'components/Loading';
import { ModalMode } from '../../../../constants';
import { MenuContext } from '../../../context';
import { addItem, editItem, updateItemImage } from '../requests';
import ItemForm from './ItemForm';

const initialValue = {
  name: '',
  isActive: true,
  sizes: [{ price: 0, serving_size: 1, name: '' }],
  tax: null,
  description: '',
  image: '',
  modifiers: [],
};

function ItemDetailModal({
  mode = ModalMode.CLOSED, onClose, itemDetail: itemDetailProp, category,
}) {
  const { id: merchantId } = useParams();
  const { fetchMenuDetail, showMessage } = useContext(MenuContext);
  const { merchant } = useContext(ModifierContext);
  const [detail, setDetail] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const { data: { data: itemDetail } = {}, loading: itemLoading } = useFetchedData(itemDetailProp?.id && `/v1/items/${itemDetailProp.id}/`);

  useEffect(() => {
    if (mode === ModalMode.NEW) setDetail(initialValue);
    if (mode === ModalMode.EDIT && itemDetail) {
      setDetail({
        name: itemDetail.name,
        description: itemDetail.description,
        image: (itemDetail.images.length > 0 ? itemDetail.images[0] : itemDetail.image) || '',
        isActive: itemDetail.is_active,
        tax: itemDetail.tax_ids?.[0],
        modifiers: itemDetail.modifiers?.filter((m) => m.options_parents?.length === 0),
        cateringLabel: itemDetail.catering_label,
        dietaryTags: itemDetail.dietary_tags,
        sizes: itemDetail.sizes,
      });
    }
  }, [mode, itemDetail]);

  const onSubmit = () => {
    const data = {
      name: detail.name,
      restaurant: merchantId,
      is_active: detail.isActive,
      description: detail.description,
      category: itemDetail?.category_ids || [category.id],
      tax_ids: detail.tax ? [detail.tax] : [],
      cascade_modifiers_ids: detail.modifiers?.map((m) => m.id),
      catering_label: merchant.is_catering ? detail.cateringLabel || 'item' : '',
      dietary_tags: detail.dietaryTags,
      sizes: detail.sizes.filter(({ price, name }, index) => price !== null && (index === 0 || name)),
    };

    setLoading(true);
    (mode === ModalMode.NEW ? addItem(data) : editItem({ id: itemDetail.id, data })).then((res) => {
      if (detail.image.id) {
        updateItemImage({ id: detail.image.id, data: { action: 'ADD', type: 'ITEM', id: mode === ModalMode.NEW ? res.id : itemDetail.id } })
          .then((res) => {
            onClose();
            fetchMenuDetail();
            setLoading(false);
          }).catch((e) => {
            captureException(e);
            showMessage({ type: 'error', content: 'Something went wrong on uploading image! please try again' });
            setLoading(false);
          });
      } else {
        onClose();
        fetchMenuDetail();
        setLoading(false);
      }
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
      setLoading(false);
    });
  };

  return (
    <Drawer
      title={`${mode === ModalMode.NEW ? 'Add' : 'Edit'} Item`}
      placement="right"
      width={isMobile ? '100%' : 575}
      onClose={onClose}
      open={mode !== ModalMode.CLOSED}
      footer={(
        <Flex justify="end" gap={8}>
          <Button loading={loading} type="primary" onClick={onSubmit}>Save</Button>
        </Flex>
      )}
    >
      {
        itemLoading ? <Loading /> : <ItemForm detail={detail} setDetail={setDetail} />
      }
    </Drawer>
  );
}

export default ItemDetailModal;
