import React, {
  createContext, useCallback, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';
import { STEPS } from '../constants';

export const StepsContext = createContext();

function StepsProvider({ children }) {
  const [activeStep, setActiveStep] = useState(STEPS.GENERAL_INFO.id);
  const [loading, setLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);

  const getStepById = (id) => Object.keys(STEPS)[id - 1];

  const onNextStep = useCallback((form) => {
    validate(form).then(() => {
      if (activeStep === Object.keys(STEPS).length) return; // @TODO: handle it on last part of this task

      const newCompletedSteps = Array.from(new Set([...completedSteps, getStepById(activeStep)]));
      setCompletedSteps(newCompletedSteps);
      setActiveStep((step) => step + 1);
    })
      .catch((e) => {
        console.log(e);
        captureException(e);
      });
  }, [activeStep, completedSteps]);

  const onPrevStep = useCallback(() => setActiveStep((step) => Math.max((step - 1), 1)), []);

  function isActiveStep(step) {
    return step === activeStep;
  }

  const validate = (form) => {
    const { fields } = Object.entries(STEPS)[activeStep - 1][1];
    if (activeStep === STEPS.PAYMENT_INFO.id) {
      const meta = form.getFieldValue('payment_method')?.meta;
      if (meta) {
        const metaFields = meta ? Object.entries(meta?.fields) : [];
        metaFields?.map(([name]) => fields.push(['customPaymentMeta', name]));
      }
    }
    return form
      .validateFields(fields, { validateOnly: false });
  };

  const isCompleted = (step) => completedSteps.includes(getStepById(step));

  const contextValue = useMemo(() => ({
    activeStep,
    setActiveStep,
    onNextStep,
    onPrevStep,
    loading,
    setLoading,
    isActiveStep,
    validate,
    isCompleted,
  }), [activeStep, onNextStep, onPrevStep, loading, completedSteps]);

  return (
    <StepsContext.Provider value={contextValue}>
      {children}
    </StepsContext.Provider>
  );
}

export default StepsProvider;
