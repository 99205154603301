import Modifier from './Modifier';
import React, { useContext, useState } from 'react';
import { Checkbox, Divider, Flex } from 'antd';
import { modifierContext } from '../ModifierProvider';
import { getModifierMaxSelections, isAutoApplied } from '../utils';
import OptionRow from '../OptionRow';
import './style.css';
import QuantityInput from 'components/QuantityInput';

function MultiSelectModifier({ modifier, disabled }) {
  const { options } = modifier;

  return (
    <Flex vertical>
      {options.map( (option, index) => (
        <ModifierOption modifier={modifier} option={option} disabled={disabled} showDivider={index !== options.length - 1} key={index}/>
      )
      )}
    </Flex>
  );
}

const ModifierOption = ({ modifier, disabled, option, showDivider }) => {
  const maxSelections = getModifierMaxSelections(modifier);
  const {
    isSelected, handleChange, getTotalQty, getSelectedOption, getNestedOptions,
  } =
    useContext(modifierContext);
  const [showChildren, setShowChildren] = useState();
  const { supports_multiple_quantity: supportMultipleQty } = option;
  const selected = isSelected({ option, modifier });
  const qty = getSelectedOption({ option, modifier })?.qty ?? 0;
  const otherQty = getTotalQty(modifier) - qty;
  const maxQty = Math.min(maxSelections - otherQty, option.max_qty || 100);

  if (isAutoApplied(modifier) && !selected) {
    handleChange({ modifier, option, value: { ...option, qty: 1 } });
  }
  if (supportMultipleQty) {
    return (
      <Flex gap={8} key={option.id}>
        <QuantityInput
          style={{ marginRight: 15 }}
          value={qty}
          min={0}
          max={maxQty}
          className="py-2"
          onChange={(qty) => {
            if (qty === 0) {
              return handleChange({ option, modifier, value: undefined });
            }
            handleChange({ option, modifier, value: { ...option, qty } });
          }}
          disabled={disabled}
        />
        <OptionRow option={option} />
      </Flex>
    );
  }
  return (
    <div key={option.id}>
      <Checkbox
        className="omc-select-row"
        checked={isSelected({ option, modifier })}
        onChange={() => {
          setShowChildren(!showChildren);
          if (selected) {
            return handleChange({ option, modifier, value: undefined });
          }
          handleChange({
            option,
            modifier,
            value: { ...option, qty: 1, modifierTitle: modifier?.title },
          });
        }}
        disabled={
          disabled || (maxQty === 0 && !isSelected({ modifier, option }))
        }
      >
        <OptionRow option={option} showChildren={showChildren} />
      </Checkbox>
      {showChildren && getNestedOptions(option.id)?.length > 0 && getNestedOptions(option.id)?.map((m, index) => (
        <Modifier modifier={m} key={index} isNested />
      ))}
      {showDivider && <Divider className="my-3" />}
    </div>
  );
}

export default MultiSelectModifier;
