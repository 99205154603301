import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { priceTransformer } from 'utils';

function ItemPrice({
  price,
  discountedPrice,
  style,
  showZero = false,
  showPercentage = true,
  priceColor = '#666B6D',
}) {
  const hasDiscount = !!discountedPrice && Number(price || 0) !== Number(discountedPrice || 0);
  return (
    <Space align="center" style={{ ...style, gap: 4 }}>
      {hasDiscount && showPercentage && (
        <Tag color="#027C2E" style={{ borderRadius: 14, padding: '0 8px', marginRight: 0 }}>
          {Math.round((100 * (price - discountedPrice)) / price)}
          %
        </Tag>
      )}

      {hasDiscount && (
        <Typography.Text className={`font-bold text-[${priceColor}]`}>
          {priceTransformer(discountedPrice)}
        </Typography.Text>
      )}

      <Typography.Text
        delete={hasDiscount}
        className={hasDiscount && 'text-[#666B6D]'}
        style={{
          fontSize: hasDiscount ? '10px' : '14px',
          fontWeight: hasDiscount ? 'normal' : 'bold',
        }}
      >
        {+price === 0 && !showZero ? null : priceTransformer(price)}
      </Typography.Text>

    </Space>
  );
}

export default ItemPrice;
