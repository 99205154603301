import { Alert, App, Button } from 'antd';
import { getMerchantById } from 'api/merchants';
import React, { useEffect, useState } from 'react';
import http, { getHttpErrorMessage } from 'services/http';
import { getQueryStringValue } from 'utils';
import { captureException } from 'utils/errors';

function ConnectStripeAlert({ merchantId }) {
  const { message } = App.useApp();
  const STRIPE_ONBOARD_STATE_KEY = 'stripe_onboard_state';
  const [loading, setLoading] = useState(false);
  const stripeOnboardState = getQueryStringValue(STRIPE_ONBOARD_STATE_KEY);
  const [setupUrl, setSetupUrl] = useState(null);
  const [merchant, setMerchant] = useState();

  useEffect(() => {
    if (merchantId) {
      setTimeout(() => {
        getMerchantById(merchantId)
          .then((res) => {
            setMerchant(res.data);
          })
          .catch(captureException);
      }, 3000);
    }
  }, [merchantId]);

  useEffect(() => {
    if (!stripeOnboardState) return;
    if (stripeOnboardState === 'success') {
      message.success('Stripe account connected successfully!');
    } else if (stripeOnboardState === 'failure') {
      message.error('Stripe account connection failed!');
    }
  }, [stripeOnboardState]);

  useEffect(() => {
    if (merchant?.should_user_onboard_on_stripe) {
      setLoading(true);
      http.post('v1/billing/onboard/stripe/', {
        refresh_url: `${window.location.origin}/console/merchants/${merchant.id}?${STRIPE_ONBOARD_STATE_KEY}=failure`,
        return_url: `${window.location.origin}/console/merchants/${merchant.id}?${STRIPE_ONBOARD_STATE_KEY}=success`,
      }).then((res) => {
        if (res.data.setup_url) {
          setSetupUrl(res.data.setup_url);
        }
      })
        .catch((e) => {
          captureException(e);
          message.error(getHttpErrorMessage(e));
        })
        .finally(() => setLoading(false));
    }
  }, [merchant?.should_user_onboard_on_stripe]);

  if (!merchant || !merchant.should_user_onboard_on_stripe) {
    return null;
  }

  return (
    <Alert
      showIcon
      className="mt-4"
      type="warning"
      message="Connect merchant’s Stripe account."
      action={(
        <Button href={setupUrl} loading={loading} target="_blank" rel="noopener noreferrer">
          Connect Stripe
        </Button>
      )}
    />
  );
}

export default ConnectStripeAlert;
