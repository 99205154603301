import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { DatePicker, Flex, Form, Select, Space, TimePicker, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import http from 'services/http';
import { formatTime } from 'utils/date';
import EditConfirmation from '../EditConfirmation';
import { StepsContext } from '../Steps/StepsProvider';

function SchedulingInput({
  value, onChange, merchantId, isCatering,
}) {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const form = useFormInstance();
  const items = form.getFieldValue('items');
  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const scheduledFor = Form.useWatch('scheduled_for');
  const { loading: stepLoading } = useContext(StepsContext);

  useEffect(() => {
    if (!scheduledFor) {
      setSelectedTime();
      setSelectedDate();
    }
  }, [scheduledFor]);

  const timeOptions = useMemo(() => {
    if (!merchantId || isCatering) return [];
    if (selectedDate === null || selectedDate === undefined) {
      return [];
    }
    const { times } = dates[selectedDate];
    return times
      .reduce((all, d) => [...all, ...d.slots], [])
      .map((s, index) => ({
        value: index,
        label: `${formatTime(s.start)} - ${formatTime(s.end)}`,
        time: s,
      }));
  }, [selectedDate]);

  useEffect(() => {
    if (loading) return;
    if (selectedDate !== undefined && selectedDate !== null && selectedTime !== undefined && selectedTime !== null) {
      let formattedScheduledData;
      if (isCatering) {
        formattedScheduledData = `${dayjs(selectedDate).format('YYYY-MM-DD')}T${dayjs(selectedTime).format('HH:mm:ss')}`;
      } else {
        formattedScheduledData = dates[selectedDate]?.date ? `${dates[selectedDate]?.date}T${timeOptions[selectedTime]?.time?.start}` : null;
      }
      onChange(formattedScheduledData ? dayjs(formattedScheduledData).toISOString() : null);
    } else {
      onChange(null);
    }
  }, [selectedTime, loading]);

  useEffect(() => {
    if (merchantId && !isCatering) {
      setLoading(true);
      http
        .post(`v1/restaurants/${merchantId}/schedule-times/`, {
          items: [],
        })
        .then(({ data: { data } }) => {
          setDates(
            Object.entries(data)
              .filter(([_, times]) => times.length > 0)
              .map(([date, times], index) => ({
                index,
                label: moment(date)?.format('ddd, DD MMM'),
                value: index,
                date,
                times,
              })),
          );
          setLoading(false);
        });
    }
  }, [merchantId, isCatering]);

  const handleSelectDate = (value) => {
    if (items?.length) {
      setTempDate(value);
      setOpen(true);
    } else {
      setSelectedDate(value);
      setSelectedTime(null);
    }
  };
  const handleSelectTime = (value) => {
    if (items?.length) {
      setTempTime(value);
      setOpen(true);
    } else {
      setSelectedTime(value);
    }
  };

  const onConfirmEdit = () => {
    setSelectedDate(tempDate || selectedDate);
    setSelectedTime(tempTime);
    form.setFieldValue('items', []);
    setTempDate();
    setTempTime();
  };
  return (
    <Space size="large" direction={isMobile ? 'vertical' : 'horizontal'} className="w-full lg:w-2/3">
      {isCatering ? (
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Flex gap={8} align="center">
            <Typography.Text>Date:</Typography.Text>
            <DatePicker
              name="scheduled_for"
              value={selectedDate}
              onChange={handleSelectDate}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
              disabled={stepLoading}
            />
          </Flex>
          <Flex gap={8} align="center">
            <Typography.Text>Time:</Typography.Text>
            <TimePicker
              name="scheduled_for"
              value={selectedTime}
              onChange={handleSelectTime}
              showSecond={false}
              minuteStep={15}
              use12Hours
              format="hh:mm A"
              disabled={stepLoading}
            />
          </Flex>
        </Space>
      ) : (
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Flex gap={8} align="center">
            <Typography.Text>Date:</Typography.Text>
            <Select
              disabled={loading || stepLoading}
              style={{ width: 150 }}
              className="row-right-margin"
              name="scheduled_for_date"
              suffixIcon={<CalendarOutlined />}
              loading={loading}
              value={selectedDate}
              onChange={handleSelectDate}
              options={dates}
              allowClear
            />
          </Flex>
          <Flex gap={8} align="center">
            <Typography.Text>Time:</Typography.Text>
            <Select
              disabled={loading || stepLoading}
              style={{ width: 200 }}
              name="scheduled_for"
              className="row-right-margin"
              suffixIcon={<ClockCircleOutlined />}
              loading={loading}
              value={selectedTime}
              onChange={handleSelectTime}
              options={timeOptions}
              allowClear
            />
          </Flex>
        </Space>
      )}
      <EditConfirmation open={open} setOpen={setOpen} onConfirmEdit={onConfirmEdit} />
    </Space>
  );
}

export default SchedulingInput;
